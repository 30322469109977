import React, { useState, useEffect } from "react";
import { useDispatch } from "react-redux";
import SelectLocation from "../SelectLocation/SelectLocation";
import SelectCompany from "../SelectCompany/SelectCompany";
import ViewAssets from "../ViewAssets/ViewAssets";
import styled from "styled-components";
import ViewJobs from "../ViewJobs/ViewJobs";

import { verifyAuthentication } from "../../Redux/user";

const LocationContainer = styled.div`
  width: 100%;
  text-align: left;
  justify-content: top;
  min-height: 95vh;
  max-height: 95vh;
`;
const CompanyContainer = styled.div`
  width: 100%;
  text-align: left;
  justify-content: top;
  min-height: 95vh;
  max-height: 95vh;
`;

const ViewAssetsContainer = styled.div`
  width: 100%;
  display: flex;
  text-align: center;
  justify-content: center;
  min-height: 95vh;
  max-height: 95vh;
`;

const ButtonBack = styled.button`
  width: 150px;
  padding: 1.2rem;
  margin-bottom: 1%;
  background-color: white;
  border-color: white;
  font-weight: bold;
  font-size: 1rem;
  color: #2b228a;
  border-radius: 25px;
  cursor: pointer;

`;

const CurrentJobList: React.FunctionComponent = (props: any) => {
  // 0: SelectLocation
  // 1: SelectCompany
  // 2: ViewAssets
  // 3: JobForm

  const [maxView] = useState(3);
  const [view, setView] = useState(0);
  const [assetDetails, setAssetDetails] = useState("" as any);
  const [fromToDateString, setFromToDateString] = useState("" as any);

  // Handles the render switching of the different components
  // when going back and forth between pages etc.
  // Guard clause to avoid hitting the default case:
  // Cannot go below 0, and cannot exceed the maxView.
  // maxView holds the value of the component with the biggest
  // number/key assigned to a component.
  const switchComponent = (progression: boolean) => {
    dispatch(verifyAuthentication());
    if (progression && view + 1 <= maxView) {
      setView(view + 1);
    } else if (!progression && view > 0) {
      setView(view - 1);
    }
  };

  const refresh = () => {
    window.location.reload();
  }
  // Reset redux state of the location, client, & job form states
  // when a user clicks to return back to the start menu

  const dispatch = useDispatch();

  useEffect(() => {
    dispatch(verifyAuthentication());
  }, []); //eslint-disable-line

  switch (view) {
    case 0:
      return (
        <LocationContainer>
          <form onSubmit={() => refresh()}>
          <ButtonBack type="submit" >{"<-- Back"}</ButtonBack>
          </form>
          <SelectLocation controller={switchComponent} adminLogin={true} />
        </LocationContainer>
      );

    case 1:
      return (
        <CompanyContainer>
          <form onSubmit={() => setView(0)}>
          <ButtonBack type="submit" >{"<-- Back"}</ButtonBack>
          </form>
          <SelectCompany controller={switchComponent} />
        </CompanyContainer>
      );
    case 2:
      return (
        <ViewAssetsContainer>
          <ViewAssets
            controller={switchComponent}
            adminLogin={true}
            backToClients={setView}
            assetDetails={setAssetDetails}
            fromToDateString={setFromToDateString}
          />
        </ViewAssetsContainer>
      );
    case 3:
      return (
        <ViewAssetsContainer>
          <ViewJobs
            assetDetails={assetDetails}
            fromToDateString={fromToDateString}
            backTo={setView}
          />
        </ViewAssetsContainer>
      );
    default:
      return (
        <div>
          This Function is not yet supported. Default switch statement case
        </div>
      );
  }
};

export default CurrentJobList;
