import { configureStore, ThunkAction, Action, combineReducers } from "@reduxjs/toolkit";
import storage from "redux-persist/lib/storage";
import { persistReducer } from "redux-persist";
import thunk from "redux-thunk";

import userReducer from "./Redux/user";
import locationReducer from "./Redux/location";
import clientReducer from "./Redux/client"
import assetReducer from "./Redux/asset";
import jobFormReducer from "./Redux/job_form";
import adminReducer from "./Redux/admin";
import reportReducer from "./Redux/report";
import operatingLocationsReducer from "./Redux/operating_locations";
import assetManagementReducer from "./Redux/asset_management";

const initState: any = {};

// Passed as the root reducer because we need to validate if an action
// is the reset state action to completely clear the redux store data
// and remove the local storage data for the user
const rootReducer = (state = initState, action: any) => {
  if (action.type === "RESET_STATE_ACTION") {
    storage.removeItem("persist:root");

    state = {};
  }
  return reducers(state, action);
};

const reducers = combineReducers({ user: userReducer, location: locationReducer, client: clientReducer, asset: assetReducer, jobForm: jobFormReducer, admin: adminReducer, report: reportReducer, operatingLocations: operatingLocationsReducer, assetManagement: assetManagementReducer });

const persistConfig = {
  key: "root",
  storage,
};

const persistedReducer = persistReducer(persistConfig, rootReducer);

export const store = configureStore({
  reducer: persistedReducer,
  devTools: process.env.NODE_ENV !== "production",
  middleware: [thunk],
});

export type AppDispatch = typeof store.dispatch;
export type RootState = ReturnType<typeof store.getState>;
export type AppThunk<ReturnType = void> = ThunkAction<ReturnType, RootState, unknown, Action<string>>;
