import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import axios from "axios";
//import { verify } from "crypto";

declare interface LoginCredentials {
  username: string;
  password: string;
}

export const attemptUserLogin = createAsyncThunk("/login", async (data: LoginCredentials) => {
  const response = await axios.post("/login", {
    username: data.username,
    password: data.password,
  });
  return await response.data;
});

export const userLogout = createAsyncThunk("/logout", async () => {
  const response = await axios.delete("/logout");
  return await response.data;
});

export const verifyAuthentication = createAsyncThunk("/checkauth", async () => {
  const response = await axios.get("/checkauth");
  console.log(response.data);
  return await response.data;
});


export const userReducer = createSlice({
  name: "user" as string,
  initialState: {
    userId: -1 as number,
    roleId: -1 as number,
    firstName: "" as string,
    lastName: "" as string,
    sessionExists: false as boolean,
    // DEBUG VALUE
    VIEW_SWITCH: 0 as number,
  },
  reducers: {},
  extraReducers: (builder) => {
    builder.addCase(attemptUserLogin.fulfilled, (state, { payload }) => {
      state.userId = payload.id;
      state.roleId = payload.roleId;
      state.firstName = payload.firstName;
      state.lastName = payload.lastName;
      state.sessionExists = true;
    });

    builder.addCase(verifyAuthentication.fulfilled, (state, { payload }) => {
      state.sessionExists = payload;
    });
  },
});

export default userReducer.reducer;
