import React, { useState, useEffect } from "react";
import { useDispatch, useSelector, RootStateOrAny } from "react-redux";
import { Redirect } from "react-router-dom";
import styled from "styled-components";

import AdminUsers from "../Users/AdminUsers";
import AdminHome from "../AdminHome/AdminHome";
import AdminClients from "../SelectCompany/AdminClients";
import AdminLocations from "../Locations/AdminLocations";
import Reporting from "../Reporting/Reporting";
import AssetManagement from "../Assets/AssetManagement";
import Navbar from "../Navbar/NavBar";
import CurrentJobList from "../CurrentJobList/CurrentJobList";

import { verifyAuthentication } from "../../Redux/user";
import { resetAdminInitState } from "../../Redux/admin";
import { resetReportInitState } from "../../Redux/report";
import { resetOperatingLocationsInitState } from "../../Redux/operating_locations";

const MainContainer = styled.div`
  width: 95%;
  @media only screen and (min-width: 1024px) {
    height: 100vh;
  }
`;

const AdminHomeContainer = styled.div`
  width: 100%;
  display: flex;
  text-align: center;
  justify-content: center;
  min-height: 95vh;
  max-height: 95vh;
`;

const ButtonBack = styled.button`
  width: 150px;
  padding: 1.2rem;
  margin-bottom: 1%;
  background-color: white;
  border-color: white;
  font-weight: bold;
  color: #2b228a;
  border-radius: 25px;
  cursor: pointer;
`;

const ControllerAdmin: React.FunctionComponent = (props: any) => {
  // 0: AdminHome
  // 1: AdminUsers
  // 2: AdminClients
  // 3: AdminLocations
  // 4: Reporting
  // 5: AssetManagement
  // 6: Current Job List

  const [maxView] = useState(6);
  const [view, setView] = useState(0);

  // Handles the render switching of the different components
  // when going back and forth between pages etc.
  // Guard clause to avoid hitting the default case:
  // Cannot go below 0, and cannot exceed the maxView.
  // maxView holds the value of the component with the biggest
  // number/key assigned to a component.
  const switchComponent = (switchView: number) => {
    dispatch(verifyAuthentication());
    if (switchView <= maxView) {
      setView(switchView);
    } else {
      setView(0);
    }
  };

  // Reset redux state of all admin states
  const returnToBeginning = () => {
    dispatch(resetAdminInitState());
    dispatch(resetReportInitState());
    dispatch(resetOperatingLocationsInitState());
    setView(0);
  };

  const user = useSelector((state: RootStateOrAny) => state.user);
  const dispatch = useDispatch();

  useEffect(() => {
    dispatch(verifyAuthentication());
  }, []); //eslint-disable-line

  if (!user.sessionExists) {
    return <Redirect to="/login" />;
  } else {
    switch (view) {
      case 0:
        return (
          <MainContainer>
            <Navbar controller={returnToBeginning} />
            <AdminHomeContainer>
              <AdminHome controller={switchComponent} />
            </AdminHomeContainer>
          </MainContainer>
        );
      case 1:
        return (
          <MainContainer>
            <Navbar controller={returnToBeginning} />
            <AdminHomeContainer>
              <AdminUsers controller={returnToBeginning} />
            </AdminHomeContainer>
          </MainContainer>
        );
      case 2:
        return (
          <MainContainer>
            <Navbar controller={returnToBeginning} />
            <AdminHomeContainer>
              <AdminClients controller={returnToBeginning} />
            </AdminHomeContainer>
          </MainContainer>
        );
      case 3:
        return (
          <MainContainer>
            <Navbar controller={returnToBeginning} />
            <AdminHomeContainer>
              <AdminLocations controller={returnToBeginning} />
            </AdminHomeContainer>
          </MainContainer>
        );
      case 4:
        return (
          <MainContainer>
            <Navbar controller={returnToBeginning} />
            <ButtonBack onClick={() => setView(0)}>{"<-- Back"}</ButtonBack>
            <AdminHomeContainer>
              <Reporting />
            </AdminHomeContainer>
          </MainContainer>
        );
      case 5:
        return (
          <MainContainer>
            <Navbar controller={returnToBeginning} />
            <AdminHomeContainer>
              <AssetManagement controller={returnToBeginning} />
            </AdminHomeContainer>
          </MainContainer>
        );
      case 6:
        return (
          <MainContainer>
          <Navbar controller={returnToBeginning} />
          <AdminHomeContainer>
            <CurrentJobList/>
          </AdminHomeContainer>
        </MainContainer>
        );
      default:
        return <div>ADMIN - default switch statement case</div>;
    }
  }
};

export default ControllerAdmin;
