import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import axios from "axios";

declare interface UserID {
  id: number;
}

export const retrieveAssignedLocations = createAsyncThunk("/employee/locations/get", async (data: UserID) => {
  const response = await axios.get("/employee/locations/get", {
    params: { id: data.id },
  });
  return await response.data;
});

export const locationReducer = createSlice({
  name: "location" as string,
  initialState: {
    assignedLocations: [],
    chosenLocation: -1 as number,
    chosenLocationName: "" as string,
  },
  reducers: {
    resetLocationInitState: (state) => {
      state.assignedLocations = [];
      state.chosenLocation = -1;
      state.chosenLocationName = "";
    },

    setChosenLocation: (state, { payload }) => {
      state.chosenLocation = payload.ID;
      state.chosenLocationName = payload.name;
    },
  },
  extraReducers: (builder) => {
    builder.addCase(retrieveAssignedLocations.fulfilled, (state, { payload }) => {
      state.assignedLocations = payload;
    });
  },
});

export const { setChosenLocation, resetLocationInitState } = locationReducer.actions;

export default locationReducer.reducer;
