import React from "react";
import { BrowserRouter, Route, Switch, Redirect } from "react-router-dom";
import styled from "styled-components";
import Login from "./Frontend/Components/Login/Login";
import ControllerEmployee from "./Frontend/Components/ControllerEmployee/ControllerEmployee";
import ControllerAdmin from "./Frontend/Components/ControllerAdmin/ControllerAdmin";
const Theme = styled.body`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: flex-start;
  background-color: #392fa2;
  min-height: 100vh;
  height: auto !important;
  font-size: calc(10px + 2vmin);
  font-family: arial;
  overflow: auto;
`;

function App() {
  return (
    <Theme>
      <BrowserRouter>
        <Switch>
          <Route exact path="/login" component={Login} />
          <Route exact path="/employee" component={ControllerEmployee} />
          <Route exact path="/admin" component={ControllerAdmin} />
          <Redirect from="*" to="/login" />
        </Switch>
      </BrowserRouter>
    </Theme>
  );
}

export default App;
