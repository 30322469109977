import React, { useState, useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { QrReader } from "@blackbox-vision/react-qr-reader";
import CircularProgress from "@mui/material/CircularProgress";
import styled from "styled-components";

import { retrieveSpecificAsset, resetErrorMsg, resetChosenAsset } from "../../Redux/job_form";

const ButtonSubmit = styled.button`
  width: 100%;
  margin-top: 10%;
  padding: 1.25% 2.5%;
  background-color: transparent;
  font-size: 1.2rem;
  font-weight: bold;
  border-color: white;
  border-radius: 12px;
  cursor: pointer;
  color: white;

  @media only screen and (min-width: 1024px) {
    width: 25vw;
    font-size: 2rem;
  }
`;

const Container = styled.div`
  width: 100%;
  display: flex;
  text-align: center;
  justify-content: top;
  flex-direction: column;
  align-items: center;
`;

const QRCodeScanner = (props) => {
  const dispatch = useDispatch();
  const viewingScanner = props.scanner;
  const QRCodeSwitch = props.QRCodeSwitch;
  //const controller = props.controller;

  
  const [data, setData] = useState("No result");
  const [loading, setLoading] = useState(false);
  const [loadingMessage, setLoadingMessage] = useState("Loading job form");
  const [submitted, setSubmitted] = useState(false);
  //alert(data);

  const jobForm = useSelector((state) => state.jobForm);

  console.log(data);

  const cycleMessage = () => {
    const msg = [`Loading job form`, `Loading job form.`, `Loading job form..`, `Loading job form...`];
    let counter = 0;
    setInterval(() => {
      setLoadingMessage(msg[counter]);

      if (counter + 1 < msg.length) {
        counter++;
      } else {
        counter = 0;
      }
    }, 500);
  };

  const setTheData = (result, error) => {
    if (!!result) {
      setData(result?.text);
      setSubmitted(true);
      dispatch(retrieveSpecificAsset({ assetUID: result.text }));
    }

    if (!!error) {
      console.info(error);
      // viewingScanner(false);
    }
  };

  useEffect(() => {
    dispatch(resetChosenAsset());
  }, []); //eslint-disable-line

  useEffect(() => {
    if (submitted && jobForm.chosenAsset.unique_qr_id !== undefined) {
      setLoading(true);
      cycleMessage();
      setTimeout(() => {
        dispatch(resetErrorMsg());
        QRCodeSwitch();
        viewingScanner(false);
      }, 2000);
    }
  }, [submitted, jobForm.chosenAsset]); //eslint-disable-line
    

  if (!loading) {
    return (
      <div>
        
        <div style={{ color: "white", marginBottom: "10%", textAlign: "center" }}>{jobForm.error}</div>
        <QrReader
          constraints={{ facingMode: `environment` }}
          onResult={(result, error) => {
            setTheData(result, error);
          }}
          style={{ width: "100% !important" }}
        />
        <Container><ButtonSubmit onClick={() => viewingScanner(false)}>CANCEL</ButtonSubmit></Container>
        
      </div>
    );
  } else {
    return (
      <div style={{ display: "flex", width: "100% !important", height: "60vh", color: "white", flexDirection: "column", justifyContent: "center", margin: "0 auto", textAlign: "center" }}>
        <div style={{ marginBottom: "5%" }}>
          <CircularProgress className={"circ-prog"} size={"4em"} />
        </div>
        <div>{loadingMessage}</div>
      </div>
    );
  }
};

export default QRCodeScanner;
