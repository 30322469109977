import React, { useState, useEffect } from "react";
import { useDispatch, useSelector, RootStateOrAny } from "react-redux";
import styled from "styled-components";
import { Formik, Form, Field } from "formik";
import CircularProgress from "@mui/material/CircularProgress";

import CheckboxCustom from "../CheckboxCustom/CheckboxCustom";

import {
  updateUserDetails,
  resetAmendingUser,
  addNewUser,
  resetWasUserAdded,
} from "../../Redux/admin";

const Container = styled.div`
  width: 60%;
  display: flex;
  text-align: center;
  justify-content: center;
  flex-direction: column;
  align-items: center;
`;

const Button = styled.button`
  width: 60%;
  padding: 1rem;
  background-color: transparent;
  border-color: white;
  margin: 2rem;
  font-size: 2rem;
  font-weight: bold;
  color: white;
  border-radius: 25px;
  cursor: pointer;
`;

const FormField = styled.div`
  color: black;
  padding: 0.5rem;
  font-size: 2rem;
  margin-bottom: 2%;
`;

const FIELD_STYLING = {
  width: "50%",
  fontSize: "1.5rem",
  paddingLeft: "12.5%",
  paddingRight: "12.5%",
  paddingTop: "5%",
  paddingBottom: "5%",
};

const RADIO = {
  border: "0px",
  width: "20px",
  height: "20px",
  verticalAlign: "middle",
};

const FormLabel = styled.label`
  color: white;
  font-weight: bold;
`;

const PageTitle = styled.div`
  color: #ffffff;
  text-align: center;
  font-size: 3rem;
  margin-bottom: 3%;
  font-weight: bold;
`;

declare interface userDetails {
  userId: number;
  username: string;
  password: string;
  firstName: string;
  lastName: string;
  isActive: boolean;
  userRole: number;
}

const UsersForm = (props: any) => {
  const [submitted, setSubmitted] = useState(false);
  const [displayError, setDisplayError] = useState(false);
  const [displayErrorRole, setDisplayErrorRole] = useState(false);
  const [passwordInputDisabled, setPasswordInputDisabled] = useState(true as any);
  const [userDetailsInputDisabled, setUserDetailsInputDisabled] = useState(false as any);
  const admin = useSelector((state: RootStateOrAny) => state.admin);
  const dispatch = useDispatch();

  const displayPasswordNotMatchError = () => {
    setDisplayError(true);
    setTimeout(() => {
      setDisplayError(false);
    }, 3500);
  };

  const displayRoleNotSelectedError = () => {
    setDisplayErrorRole(true);
    setTimeout(() => {
      setDisplayErrorRole(false);
    }, 3500);
  };

  // Resetting for the useEffect below
  useEffect(() => {
    dispatch(resetAmendingUser());
    dispatch(resetWasUserAdded());
  }, []); //eslint-disable-line

  useEffect(() => {
    if (admin.amendingUser === "updated" && submitted) {
      setTimeout(() => {
        props.setViewingForm(false);
      }, 1500);
    }
  }, [admin.amendingUser]); //eslint-disable-line

  useEffect(() => {
    if (admin.wasUserAdded === "Successfully added new user!" && submitted) {
      setTimeout(() => {
        props.setViewingForm(false);
      }, 2500);
    }
  }, [admin.wasUserAdded]); //eslint-disable-line

  const resetPasswordBtn = () => {
    setPasswordInputDisabled(false);
    setUserDetailsInputDisabled(true);
  }

  // Amending a user
  if (admin.userToAmend !== null) {
    return (
      <Container>
        <PageTitle>Editing User</PageTitle>
        <Formik
          initialValues={{
            userId: admin.userToAmend.idusers as number,
            username: admin.userToAmend.username as string,
            firstName: admin.userToAmend.firstName as string,
            lastName: admin.userToAmend.lastName as string,
            isActive: admin.userToAmend.isActive as boolean,
            userRole: admin.userToAmend.userRole as number,
            password: admin.userToAmend.password as string,
            passwordConfirm: "" as string,
          }}
          onSubmit={async (values) => {
            const details: userDetails = {
              userId: values.userId,
              username: values.username,
              firstName: values.firstName,
              lastName: values.lastName,
              isActive: values.isActive,
              password: values.password,
              userRole: values.userRole,
            };
            if (details.password === values.passwordConfirm || passwordInputDisabled) {
              dispatch(updateUserDetails(details));
              setSubmitted(true);
            } else {
              displayPasswordNotMatchError();
            }
          }}
        >
          <Form>
            <tr>
              <th>
                
                <FormLabel>Username</FormLabel>
                <FormField>
                  <Field
                    disabled={userDetailsInputDisabled}
                    id="username"
                    style={FIELD_STYLING}
                    name="username"
                    autoComplete="new-password"
                  />
                </FormField>
                <FormLabel>First Name</FormLabel>
                <FormField>
                  <Field
                    disabled={userDetailsInputDisabled}
                    id="firstName"
                    style={FIELD_STYLING}
                    name="firstName"
                    autoComplete="new-password"
                  />
                </FormField>
                <FormLabel>Last Name</FormLabel>
                <FormField>
                  <Field
                    disabled={userDetailsInputDisabled}
                    id="lastName"
                    style={FIELD_STYLING}
                    name="lastName"
                    autoComplete="new-password"
                  />
                </FormField>
                <FormField>
                  <FormLabel>Active User</FormLabel>
                  <Field
                    disabled={userDetailsInputDisabled}
                    id="isActive"
                    component={CheckboxCustom}
                    name="isActive"
                    autoComplete="new-password"
                  />
                </FormField>
              </th>
              <th>
                
                <FormLabel>New Password</FormLabel>
                <FormField>
                  <Field
                    disabled={passwordInputDisabled}
                    id="password"
                    style={FIELD_STYLING}
                    name="password"
                    type="password"
                    autoComplete="new-password"
                  />
                </FormField>
                <FormLabel>Confirm Password</FormLabel>
                <FormField>
                  <Field
                    disabled={passwordInputDisabled}
                    id="passwordConfirm"
                    style={FIELD_STYLING}
                    name="passwordConfirm"
                    type="password"
                    autoComplete="new-password"
                  />
                </FormField>
                {!userDetailsInputDisabled ? (<FormField><Button type="button" onClick={(resetPasswordBtn)}>Reset Password</Button></FormField>) : null}
                {displayError ? (
              <div style={{ color: "white", fontSize: "1.5rem" }}>
                Passwords do not match.
              </div>
            ) : null}
              </th>
            </tr>

            <Button type="submit">{!userDetailsInputDisabled ? ("Update Details") : ("Reset")}</Button>
            {/* Display loading whilst waiting on server response */}
            {admin.amendingUser === "loading" ? (
              <div>
                <CircularProgress className={"circ-prog"} size={"3em"} />
              </div>
            ) : null}
            {admin.amendingUser !== undefined ? (
              <div style={{ color: "white", fontSize: "1.5rem" }}>
                {admin.amendingUser}
              </div>
            ) : null}
          </Form>
        </Formik>
      </Container>
    );
  } else {
    return (
      <Container>
        <PageTitle>Adding New User</PageTitle>
        <Formik
          initialValues={{
            username: "" as string,
            password: "" as string,
            passwordConfirm: "" as string,
            firstName: "" as string,
            lastName: "" as string,
            userRole: 0 as number,
          }}
          onSubmit={async (values) => {
            const details: userDetails = {
              userId: -1,
              username: values.username,
              password: values.password,
              firstName: values.firstName,
              lastName: values.lastName,
              isActive: true,
              userRole: values.userRole,
            };
            
            if ((details.password === values.passwordConfirm) && (details.userRole !== 0)) {
              dispatch(addNewUser(details));
              setSubmitted(true);
            } 
            else if (details.password !== values.passwordConfirm) {
              displayPasswordNotMatchError();
            } else {
              displayRoleNotSelectedError();
            }

          }}
        >
          <Form>
            <tr>
              <th>
                <FormLabel>Username</FormLabel>
                <FormField>
                  <Field
                    id="username"
                    style={FIELD_STYLING}
                    name="username"
                    autoComplete="new-password"
                  />
                </FormField>
                <FormLabel>Password</FormLabel>
                <FormField>
                  <Field
                    id="password"
                    style={FIELD_STYLING}
                    name="password"
                    type="password"
                    autoComplete="new-password"
                  />
                </FormField>
                <FormLabel>Password Confirm</FormLabel>
                <FormField>
                  <Field
                    id="passwordConfirm"
                    style={FIELD_STYLING}
                    name="passwordConfirm"
                    type="password"
                    autoComplete="new-password"
                  />
                </FormField>
              </th>
              <th>
                <FormLabel>First Name</FormLabel>
                <FormField>
                  <Field
                    id="firstName"
                    style={FIELD_STYLING}
                    name="firstName"
                    autoComplete="new-password"
                  />
                </FormField>
                <FormLabel>Last Name</FormLabel>
                <FormField>
                  <Field
                    id="lastName"
                    style={FIELD_STYLING}
                    name="lastName"
                    autoComplete="new-password"
                  />
                </FormField>

                <FormLabel id="my-radio-group">User Role</FormLabel>

                <FormField role="group" aria-labelledby="my-radio-group">
                  <table>
                    <tr>
                      <td>
                        <FormLabel>Admin</FormLabel>
                      </td>
                      <td>
                        {/* Admin */}
                        <Field
                          type="radio"
                          id="userRole"
                          style={RADIO}
                          name="userRole"
                          autoComplete="new-password"
                          value="1"
                        />
                      </td>
                    </tr>
                    <tr>
                      <td>
                        <FormLabel>Employee</FormLabel>
                      </td>
                      <td>
                        {/* Employee */}
                        <Field
                          type="radio"
                          id="userRole"
                          style={RADIO}
                          name="userRole"
                          autoComplete="new-password"
                          value="2"
                        />
                      </td>
                    </tr>
                  </table>
                </FormField>
              </th>
            </tr>
            {/* Display this error message if the user tries to submit the form, but passwords don't match */}
            {displayError ? (
              <div style={{ color: "white", fontSize: "1.5rem" }}>
                Passwords do not match.
              </div>
            ) : null}
            {/* Display this error message if the user tries to submit the form, but passwords don't match */}
            {displayErrorRole ? (
              <div style={{ color: "white", fontSize: "1.5rem" }}>
                You must assign a role to a user.
              </div>
            ) : null}            
            <Button type="submit">Add User</Button>
            {/* Display loading whilst waiting on server response */}
            {admin.wasUserAdded === "loading" ? (
              <div>
                <CircularProgress className={"circ-prog"} size={"3em"} />
              </div>
            ) : null}
            {/* Show user feedback message based on what the backend responded with */}
            {admin.wasUserAdded !== "" && admin.wasUserAdded !== "loading" ? (
              <div style={{ color: "white" }}>{admin.wasUserAdded}</div>
            ) : null}
          </Form>
        </Formik>
      </Container>
    );
  }
};

export default UsersForm;
