import React, { useState, useEffect } from "react";
import { useDispatch, useSelector, RootStateOrAny } from "react-redux";
import styled from "styled-components";
import CircularProgress from "@mui/material/CircularProgress";

import { retrieveActiveLocations, retrieveInactiveLocations, clearLocations, updateLocationDetails } from "../../Redux/operating_locations";

const Container = styled.div`
  width: 100%;
  display: flex;
  text-align: center;
  justify-content: center;
  flex-direction: column;
  align-items: center;
  color: white;
  border-top: solid 3px white;
  padding-top: 2%;
`;

const ChooseActivity = styled.div`
  width: 50%;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
`;

const ActiveButton = styled.button`
  width: 85%;
  padding: 5%;
  margin-bottom: 5%;
  background-color: white;
  border-color: transparent;
  font-weight: bold;
  font-size: 1.2rem;
  color: #392fa2;
  border-radius: 25px;
  cursor: pointer;
  justify-content: space-around;
`;

const InactiveButton = styled.button`
  width: 85%;
  padding: 5%;
  margin-bottom: 5%;
  background-color: transparent;
  border-color: white;
  font-weight: bold;
  font-size: 1.2rem;
  color: white;
  border-radius: 25px;
  cursor: pointer;
  justify-content: space-around;
`;

const PageTitle = styled.div`
  color: #ffffff;
  text-align: center;
  font-size: 2.5rem;
  font-weight: bold;
  margin-bottom: 5%;
`;

// const Button = styled.button`
//   width: 60%;
//   padding: 1rem;
//   background-color: transparent;
//   border-color: white;
//   margin: 2rem;
//   font-size: 2rem;
//   font-weight: bold;
//   color: white;
//   border-radius: 25px;
//   cursor: pointer;
// `;

const ClientLocations = (props: any) => {
  const [loading, setLoading] = useState(true);
  const admin = useSelector((state: RootStateOrAny) => state.admin);
  const operatingLocations = useSelector((state: RootStateOrAny) => state.operatingLocations);
  const dispatch = useDispatch();

  useEffect(() => {
    setTimeout(() => {
      setLoading(false);
    }, 2000);
    dispatch(clearLocations());
    dispatch(retrieveActiveLocations({ clientId: admin.clientToAmend.idclients }));
    dispatch(retrieveInactiveLocations({ clientId: admin.clientToAmend.idclients }));
  }, []); //eslint-disable-line

  const updatingActiveStatus = (data: any) => {
    dispatch(updateLocationDetails({ clientId: admin.clientToAmend.idclients, locationId: data.idlocations }));
  };

  if (!loading && !operatingLocations.loading) {
    return (
      <Container>
        <PageTitle>Operating Locations</PageTitle>
        <ChooseActivity>
          <div style={{ width: "50%", flexDirection: "column" }}>
            <div style={{ marginBottom: "5%", fontWeight: "bold" }}>Active</div>
            {operatingLocations.activeLocations.map((k: any, i: any) => {
              return (
                <ActiveButton key={k.idlocations} onClick={() => updatingActiveStatus(k)}>
                  {k.name}
                </ActiveButton>
              );
            })}
          </div>
          <div style={{ width: "50%", flexDirection: "column" }}>
            <div style={{ marginBottom: "5%", fontWeight: "bold" }}>Inactive</div>
            {operatingLocations.inactiveLocations.map((k: any, i: any) => {
              return (
                <InactiveButton key={k.idlocations} onClick={() => updatingActiveStatus(k)}>
                  {k.name}
                </InactiveButton>
              );
            })}
          </div>
        </ChooseActivity>
        {/* <Button onClick={() => props.setViewingForm(false)}>Done</Button> */}
        <div style={{ marginBottom: "5%", fontWeight: "bold" }}>Please select 'Admin Home' when you are done</div>
      </Container>
      
    );
  } else {
    return (
      <Container>
        <PageTitle>Operating Locations</PageTitle>
        <div>Loading...</div>
        <CircularProgress className={"circ-prog-client-locations"} size={"3em"} />
      </Container>
    );
  }
};

export default ClientLocations;
