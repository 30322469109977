import React, { useState, useEffect } from "react";
import { CSVLink } from "react-csv";
import { useDispatch, useSelector, RootStateOrAny } from "react-redux";
import { format, startOfMonth, endOfMonth, getMonth } from "date-fns";
import styled from "styled-components";
import Select from "react-select";
import CircularProgress from "@mui/material/CircularProgress";
import DownloadIcon from "@mui/icons-material/Download";

import { retrieveReportJobHistory, retrieveReportJobSummary } from "../../Redux/report";
import { retrieveClients, retrieveLocations } from "../../Redux/admin";
import { retrieveActiveLocations } from "../../Redux/operating_locations";

const Container = styled.div`
  width: 40%;
  display: flex;
  text-align: center;
  justify-content: top;
  color: white;
  flex-direction: column;
`;

const PageTitle = styled.div`
  color: #ffffff;
  text-align: center;
  font-size: 3rem;
  margin-bottom: 3%;
  font-weight: bold;
`;

const Input = styled.input`
  width: 90%;
  margin: 0 auto;
  font-size: 1.5rem;
`;

const Subheading = styled.div`
  font-size: 1.5rem;
  font-weight: bold;
  margin-bottom: 1%;
  height: 40px;
  padding-top: 10px;
`;

const Export = styled.div`
  margin-top: 5%;
`;

const SelectContainer = styled.div`
  width: 70%;
  margin: 0 auto;
  color: black;
`;


const Button = styled.button`
  width: 90%;
  padding: 1rem;
  background-color: transparent;
  border-color: white;
  margin: 2rem;
  height: 8vh;
  font-size: 2rem;
  font-weight: bold;
  color: white;
  border-radius: 25px;
  cursor: pointer;
`;

const Month = styled.div`
  width: 60%;
  margin: 0 auto;
`;

const Reporting = (props: any) => {
  const months = [
    "January",
    "February",
    "March",
    "April",
    "May",
    "June",
    "July",
    "August",
    "September",
    "October",
    "November",
    "December",
  ];
  const [selectedMonth, setSelectedMonth] = useState("" as any);
  const [selectedStartDate, setSelectedStartDate] = useState("" as any);
  const [selectedEndDate, setSelectedEndDate] = useState("" as any);
  const [inputDisabled, setInputDisabled] = useState(false as any);
  const [fileNameText, setFileNameText] = useState("" as any);
  const [fileNameTextSummary, setFileNameTextSummary] = useState("" as any);
  const [endDateBeforeStart, setEndDateBeforeStart] = useState(false as any);
  const [isThereDataForSelection, setIsThereDataForSelection] = useState(true as any);
  const [selectedOption, setSelectedOption] = useState("" as any);
  const admin = useSelector((state: RootStateOrAny) => state.admin);
  const report = useSelector((state: RootStateOrAny) => state.report);
  const [locationSelectedOption, locationSetSelectedOption] = useState(
    null as any
  );
  const [clientLocation, setClientLocation] = useState(-1 as number);
  const operatingLocations = useSelector(
    (state: RootStateOrAny) => state.operatingLocations
  );
  const dispatch = useDispatch();

  console.log(locationSelectedOption)
  const selectClientLocation = (e: any) => {
    locationSetSelectedOption(e);
    setClientLocation(e.idlocations);
  };

  const selectClient = (e: any) => {
    setSelectedOption(e);
    dispatch(retrieveActiveLocations({ clientId: e.idclients }));
  };

  // Triggers when month is selected
  const handleMonth = (e: any) => {
    setSelectedMonth(getMonth(new Date(e.target.value)));
    setFileNameText(
      `FullReport_${months[getMonth(new Date(e.target.value))]}_${e.target.value}_${selectedOption.label}`
    );
    dispatch(
      retrieveReportJobHistory({
        startDate: format(startOfMonth(new Date(e.target.value)), `yyyy-MM-dd`),
        endDate: format(endOfMonth(new Date(e.target.value)), `yyyy-MM-dd`),
        asset_id: "",
        assetName: selectedOption.label,
        clientLocation: clientLocation
      })
      
    );
    setFileNameTextSummary(
      `SummaryReport_${months[getMonth(new Date(e.target.value))]}_${e.target.value}_${selectedOption.label}`
    );
    dispatch(
      retrieveReportJobSummary({
        startDate: format(startOfMonth(new Date(e.target.value)), `yyyy-MM-dd`),
        endDate: format(endOfMonth(new Date(e.target.value)), `yyyy-MM-dd`),
        asset_id: "",
        assetName: selectedOption.label,
        clientLocation: clientLocation
      })
    );
    if (selectedMonth === "") {
      setIsThereDataForSelection(false);
    }
  };

  const handleStartDate = (e: any) => {
    setInputDisabled(true);
    setSelectedStartDate(e.target.value);
  };

  const handleEndDate = (e: any) => {
    setInputDisabled(true);
    if (
      new Date(selectedStartDate).getTime() > new Date(e.target.value).getTime()
    ) {
      setEndDateBeforeStart(true);
    } else {
      setEndDateBeforeStart(false);
      setSelectedEndDate(e.target.value);
    }
  };

  const handleSubmit = () => {
    setFileNameText(
      `FullReport_${format(new Date(selectedStartDate), `yyy-MM-dd`)}_to_${format(
        new Date(selectedEndDate),
        `yyy-MM-dd`
      )}_${selectedOption.label}`
    );
    dispatch(
      retrieveReportJobHistory({
        startDate: format(new Date(selectedStartDate), `yyy-MM-dd`),
        endDate: format(new Date(selectedEndDate), `yyy-MM-dd`),
        asset_id: "",
        assetName: selectedOption.label,
        clientLocation: clientLocation
      })
    );
    setFileNameTextSummary(
      `SummaryReport_${format(new Date(selectedStartDate), `yyy-MM-dd`)}_to_${format(
        new Date(selectedEndDate),
        `yyy-MM-dd`
      )}_${selectedOption.label}`
    );
    dispatch(
      retrieveReportJobSummary({
        startDate: format(new Date(selectedStartDate), `yyy-MM-dd`),
        endDate: format(new Date(selectedEndDate), `yyy-MM-dd`),
        asset_id: "",
        assetName: selectedOption.label,
        clientLocation: clientLocation
      })
    );
    if (report.jobHistory.length === 0) {
      setIsThereDataForSelection(false);
    }
  };

  const resetFields = () => {
    setInputDisabled(false);
    setSelectedStartDate("");
    setSelectedEndDate("");
    setSelectedMonth("");
    setFileNameText("");
    setIsThereDataForSelection(true);
  };

  useEffect(() => {
    dispatch(retrieveClients());
    dispatch(retrieveLocations());
  }, []); //eslint-disable-line

  return (
    <Container>
      <PageTitle>Reporting</PageTitle>
      <Subheading>Select Client</Subheading>
        <SelectContainer>
          <Select
            value={selectedOption}
            onChange={(e) => selectClient(e)}          
            options={admin.clients}
            placeholder={"Default: All Clients"}
          />
        </SelectContainer>
      <Subheading>Select a Location</Subheading>
        <SelectContainer>
              <Select
                value={locationSelectedOption}
                onChange={(e) => selectClientLocation(e)}
                options={operatingLocations.activeLocations}
                placeholder={"Select a location..."}
              />
              {console.log(operatingLocations.activeLocations)}
            </SelectContainer>
      <Subheading>Select a Month</Subheading>
      <Month>
        <Input
          disabled={inputDisabled}
          type="month"
          onChange={(e) => handleMonth(e)}
        />
      </Month>
      <Subheading>OR</Subheading>
      <Subheading>Custom Range</Subheading>

      <table>
        <tr>
          <th>
            <Subheading>Start Date</Subheading>
          </th>
          <th>
            <Subheading>End Date</Subheading>
          </th>
        </tr>
        <tr>
          <td>
            <Input type="date" onChange={(e) => handleStartDate(e)} />
          </td>
          <td>
            <Input type="date" onChange={(e) => handleEndDate(e)} />
          </td>
        </tr>
        {selectedStartDate !== "" && selectedEndDate !== "" ? (
          <tr>
            <td>
              <Button onClick={handleSubmit}>Generate</Button>
            </td>
            <td>
              <Button onClick={resetFields}>Reset</Button>
            </td>
          </tr>
        ) : null}
      </table>

      {endDateBeforeStart ? (
          <Subheading>The end date must be after the start date</Subheading>
        ) : null}

      {report.loading !== "loading" &&
      (selectedMonth !== "" || (selectedEndDate !== "" && selectedStartDate !== "")) &&
      report.jobHistory.length > 0 ? (
        <Export>
            <CSVLink
            style={{ color: "white" }}
            data={report.jobHistory}
            filename={fileNameText}
            className="btn btn-primary dlCSV"
            target="_blank"
            onClick={resetFields}
            >
            <DownloadIcon style={{ fontSize: "35px" }} />{" "}
            {`${fileNameText}.csv`}
          </CSVLink>
          <p></p>
          <CSVLink
            style={{ color: "white" }}
            data={report.jobSummary}
            filename={fileNameTextSummary}
            className="btn btn-primary dlCSV"
            target="_blank"
            onClick={resetFields}
            >
          <DownloadIcon style={{ fontSize: "35px" }} />{" "}
            {`${fileNameTextSummary}.csv`}
          </CSVLink>

        </Export>
      ) : null}
      {report.loading === "loading" ? (
        <div>
          <CircularProgress className={"circ-prog"} size={"3em"} />
        </div>
      ) : null}
      {report.loading !== "loading" && !isThereDataForSelection && report.jobHistory.length === 0 ? (
        <p>No data for this date range.</p>
      ) : null}
    </Container>
  );
};

export default Reporting;



