import React, { useState, useEffect } from "react";
import { useDispatch, useSelector, RootStateOrAny } from "react-redux";
import { Formik, Form, Field } from "formik";
import CircularProgress from "@mui/material/CircularProgress";
import styled from "styled-components";

import ControllerMain from "../ControllerMain/ControllerMain";

import { attemptUserLogin, verifyAuthentication } from "../../Redux/user";
import { resetState } from "../../Redux/reset_store";

import avcroLogo from "../../Media/image.png";

import "./Login.css";

const Container = styled.div`
  width: 100%;
  display: flex;
  text-align: center;
  justify-content: center;
`;

// const PageTitle = styled.div`
//   color: #ffffff;
//   text-align: center;
//   font-size: 6rem;
//   font-weight: bold;

//   @media only screen and (min-width: 1024px) {
//     font-size: 10rem;
//   }
// `;

const FormField = styled.div`
  color: black;
  padding: 1rem;
  font-size: 2rem;
  & button {
    width: 70%;
    margin-top: 5%;
    padding: 2.5% 5%;
    background-color: transparent;
    font-size: 2rem;
    font-weight: bold;
    color: white;
    border-color: white;
    border-radius: 12px;
    cursor: pointer;
  }
`;

const FormContainer = styled.div`
  display: flex;
  width: 100%;
  flex-direction: column;
`;

const FIELD_STYLING = {
  width: "50%",
  fontSize: "1.5rem",
  paddingLeft: "12.5%",
  paddingRight: "12.5%",
  paddingTop: "5%",
  paddingBottom: "5%",
};

const ButtonSubmit = styled.button`
  width: 70%;
  margin-top: 5%;
  padding: 2.5% 5%;
  background-color: transparent;
  font-size: 2rem;
  font-weight: bold;
  color: white;
  border-color: white;
  border-radius: 12px;
  cursor: pointer;
`;

const Login = (props: any) => {
  const [submitted, setSubmitted] = useState(false);
  //const [credentialsCorrect, setCredentialsCorrect] = useState(true);
  const user = useSelector((state: RootStateOrAny) => state.user);
  const dispatch = useDispatch();

  useEffect(() => {
    dispatch(verifyAuthentication());

    if (!user.sessionExists) {
      dispatch(resetState());
    } 
  }, []); //eslint-disable-line

  if (user.sessionExists) {
    return <ControllerMain />;
  } else {
    return (
      <Container>
        <div>
          <img
            id="logo"
            src={avcroLogo}
            alt="Avcro Logo"
            width="400"
            height="160"
          ></img>
          <Formik
            initialValues={{
              username: "" as string,
              password: "" as string,
            }}
            onSubmit={async (values) => {
              setSubmitted(true);
              dispatch(attemptUserLogin(values));
            }}
          >
            <FormContainer>
              <Form>
                <FormField>
                  <Field
                    style={FIELD_STYLING}
                    id="username"
                    name="username"
                    placeholder="Username"
                    autoComplete="new-password"
                  />
                </FormField>
                <FormField>
                  <Field
                    style={FIELD_STYLING}
                    id="password"
                    name="password"
                    type="password"
                    placeholder="Password"
                    autoComplete="new-password"
                  />
                </FormField>
                <ButtonSubmit type="submit">Login</ButtonSubmit>
                {/* Display loading whilst waiting on server response */}
                {submitted ? (
                  <div>
                    <CircularProgress className={"circ-prog"} size={"4em"} />
                  </div>
                ) : null}
                {/* {!credentialsCorrect ? (
                  <div>
                    <p>Username or password is incorrect</p>
                  </div>
                ) : null} */}
              </Form>
            </FormContainer>
          </Formik>
        </div>
      </Container>
    );
  }
};

export default Login;
