import React, { forwardRef } from "react";
import MaterialTable from "@material-table/core";
import { Icons } from "@material-table/core";
import AddBox from "@material-ui/icons/AddBox";
import ArrowUpward from "@material-ui/icons/ArrowUpward";
import Check from "@material-ui/icons/Check";
import ChevronLeft from "@material-ui/icons/ChevronLeft";
import ChevronRight from "@material-ui/icons/ChevronRight";
import Clear from "@material-ui/icons/Clear";
import DeleteOutline from "@material-ui/icons/DeleteOutline";
import Edit from "@material-ui/icons/Edit";
import FilterList from "@material-ui/icons/FilterList";
import FirstPage from "@material-ui/icons/FirstPage";
import LastPage from "@material-ui/icons/LastPage";
import Remove from "@material-ui/icons/Remove";
import SaveAlt from "@material-ui/icons/SaveAlt";
import Search from "@material-ui/icons/Search";
import ViewColumn from "@material-ui/icons/ViewColumn";

const TABLE_ICONS: Icons = {
  Add: forwardRef((props, ref) => <AddBox {...props} ref={ref} />),
  Check: forwardRef((props, ref) => <Check {...props} ref={ref} />),
  Clear: forwardRef((props, ref) => <Clear {...props} ref={ref} />),
  Delete: forwardRef((props, ref) => <DeleteOutline {...props} ref={ref} />),
  DetailPanel: forwardRef((props, ref) => <ChevronRight {...props} ref={ref} />),
  Edit: forwardRef((props, ref) => <Edit {...props} ref={ref} />),
  Export: forwardRef((props, ref) => <SaveAlt {...props} ref={ref} />),
  Filter: forwardRef((props, ref) => <FilterList {...props} ref={ref} />),
  FirstPage: forwardRef((props, ref) => <FirstPage {...props} ref={ref} />),
  LastPage: forwardRef((props, ref) => <LastPage {...props} ref={ref} />),
  NextPage: forwardRef((props, ref) => <ChevronRight {...props} ref={ref} />),
  PreviousPage: forwardRef((props, ref) => <ChevronLeft {...props} ref={ref} />),
  ResetSearch: forwardRef((props, ref) => <Clear {...props} ref={ref} />),
  Search: forwardRef((props, ref) => <Search {...props} ref={ref} />),
  SortArrow: forwardRef((props, ref) => <ArrowUpward {...props} ref={ref} />),
  ThirdStateCheck: forwardRef((props, ref) => <Remove {...props} ref={ref} />),
  ViewColumn: forwardRef((props, ref) => <ViewColumn {...props} ref={ref} />),
};

/*
API Declaration

<Table
    title={""}
    col={[
      { field: "id", title: "Id" },
      { field: "name", title: "Name" },
    ]}
    data={[
      { id: 2, name: "Joe" },
      { id: 1, name: "Mary" },
    ]}
    styles={{ width: "60%", margin: "0 auto", fontSize: "0.5em", fontFamily: "arial" }}
 />
*/

const Table = (props: any) => {
  const TITLE = props.title;
  const DATA = props.data;
  const COLUMNS = props.col;
  const STYLES = props.styles;
  const HEADER_STYLES = props.headerStyles;
  const ROW_STYLES = props.rowStyles;
  const FILTER_STYLES = props.filterStyles;
  const SEARCH_STYLES = props.searchStyles;

  return (
    <div style={{ width: "100%" }}>
      <MaterialTable
        title={TITLE}
        icons={TABLE_ICONS}
        data={DATA}
        columns={COLUMNS}
        style={STYLES}
        options={{
          search: true,
          filtering: true,
          headerStyle: HEADER_STYLES,
          rowStyle: ROW_STYLES,
          filterCellStyle: FILTER_STYLES,
          searchFieldStyle: SEARCH_STYLES,
        }}
      />
    </div>
  );
};
export default Table;
