import styled from "styled-components";
import React, { useEffect, useState } from "react";
import { useDispatch, useSelector, RootStateOrAny } from "react-redux";
import Table from "../Table/Table";
//import { format, startOfMonth, endOfMonth } from "date-fns";
import { retrieveReportJobHistory } from "../../Redux/report";
import { Modal, makeStyles } from "@material-ui/core";
import "./ViewJobs.css";
import ReactMapboxGl, { Marker } from "react-mapbox-gl";
import "mapbox-gl/dist/mapbox-gl.css";
import marker from "../../Media/marker3.png";

const Container = styled.div`
  width: 100%;
  text-align: center;
  justify-content: center;
  color: white !important;
`;

const Button = styled.div`
  padding: 0.5rem;
  background-color: white;
  font-size: 1rem;
  font-weight: bold;
  color: #392fa2;
  border-radius: 25px;
  cursor: pointer;
  text-align: center;
`;

const Text = styled.text`
  display: table-cell;
  vertical-align: bottom;
  font-size: 1rem;
  color: white !important;
`;

const Map = ReactMapboxGl({
  accessToken:
    "pk.eyJ1IjoiY291bnRyeWNvbnN1bHRpbmciLCJhIjoiY2w1a2w1eXlwMDg2MDNqcGFsb3pjem82eCJ9.JGWIF2-9oZS7ce_hjqssmg",
});

function rand() {
  return Math.round(Math.random() * 20) - 10;
}
function getModalStyle() {
  const top = 50 + rand();
  const left = 50 + rand();
  return {
    top: `${top}%`,
    left: `${left}%`,
    transform: `translate(-${top}%, -${left}%)`,
  };
}

// Used for the Modal styling
const useStyles = makeStyles((theme) => ({
  modal: {
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
  },
  paper: {
    position: "absolute",
    width: 800,
    backgroundColor: theme.palette.background.paper,
    boxShadow: theme.shadows[5],
    padding: theme.spacing(2, 4, 3),
  },
}));

const ViewAssets = React.memo((props: any) => {
  const report = useSelector((state: RootStateOrAny) => state.report);
  const dispatch = useDispatch();
  const classes = useStyles();
  const [modalStyle] = useState(getModalStyle);
  //MapBox data state
  const [open, setOpen] = useState(false);
  const [selectedJobLong, setSelectedJobLong] = useState(0.0);
  const [selectedJobLat, setSelectedJobLat] = useState(0.0);
  const [selectedClient, setSelectedClient] = useState("");
  const [selectedClientDescription, setSelectedClientDescription] = useState("");
  const [selectedIdAsset, setSelectedIdAsset] = useState("");
  const [mapQRScanned, setMapQRScanned] = useState("");

  // Handles states for mapBox data
  const handleOpen = (long: any, lat: any, client: string, description: string, assetId: string, location: string) => {
    setSelectedJobLong(long)
    setSelectedJobLat(lat)
    setSelectedClient(client)
    setSelectedClientDescription(description)
    setSelectedIdAsset(assetId)
    setMapQRScanned(location);
    setOpen(true);
  };
  const handleClose = () => {
    setOpen(false);
  };

  useEffect(() => {
    dispatch(
      retrieveReportJobHistory({
        startDate: props.fromToDateString.from,
        endDate: props.fromToDateString.to,
        asset_id: props.assetDetails.asset_id,
        assetName: "",
        clientLocation: ""
      })
    );
  }, []); //eslint-disable-line

  const goBackAssets = () => {
    props.backTo(2);
  };

  return (
    <Container>
      <div className="assets-table">
        <Table
          title={
            <table>
              <thead>
                <td>
                  <Button onClick={goBackAssets}>{"<-- Back to Assets"}</Button>
                </td>
                <td>
                  <Text>
                    Viewing jobs list for: {props.assetDetails.asset_id} -{" "}
                    {props.assetDetails.description}
                  </Text>
                </td>
              </thead>
              <tbody>
                <td></td>
                <td>
                  <Text>
                    Between Dates: {props.fromToDateString.from} -{" "}
                    {props.fromToDateString.to}
                  </Text>
                </td>
              </tbody>
            </table>
          }
          col={[
            {
              field: "JobCompletedBy",
              title: "Technicians Name",
            },
            {
              field: "job_date",
              title: "Job Date",
            },
            {
              field: "job_time",
              title: "Job Time",
            },
            {
              field: "hours",
              title: "Hours",
            },
            {
              field: "litres",
              title: "Litres",
            },
            {
              field: "notes",
              title: "Notes",
            },
            {
              title: "View Map",
              field: "idasset_job_history",
              render: (rowData: any) => (
                rowData.longitude && rowData.latitude !== 0.0?
                <Button
                  style={{ backgroundColor: "Green", color: "white" }}
                  onClick={() => {
                    handleOpen(rowData.longitude, rowData.latitude, rowData.Client, rowData.client_description, rowData.asset_id_client, rowData.location);
                    
                  }}
                >
                  View Job Location
                </Button> : <p>No Location Data Available</p>
              ),
            },
          ]}
          data={report.jobHistory}
          styles={{
            width: window.screen.width > 768 ? "90%" : "100%",
            margin: "0 auto",
            fontSize: "0.5em",
            fontFamily: "arial",
            backgroundColor: "#2b228a",
            color: "white !important",
          }}
          headerStyles={{
            backgroundColor: "#2b228a",
            color: "white",
          }}
          rowStyles={{
            color: "white",
          }}
          filterStyles={{
            color: "white !important",
          }}
          searchStyles={{
            color: "white !important",
            backgroundColor: "white !important",
          }}
        />
        <Modal
          aria-labelledby="simple-modal-title"
          aria-describedby="simple-modal-description"
          open={open}
          onClose={handleClose}
        >
          <div style={modalStyle} className={classes.paper}>
            <p style={{ color:"red", padding: 0, fontSize: "80%" }}>{mapQRScanned!==""? "*NOTE: The vehicles QR Code was not scanned on for this job. Location may not be accurate." : null}</p>
            <p>
              <b>{selectedClient}</b><br/>
              {selectedClientDescription}<br/>
              {selectedIdAsset}
              <h2>
              Coordinates 
            </h2>
            Latitude: {selectedJobLat} Longitude: {selectedJobLong}<br/>
            </p>
            <Map
              // eslint-disable-next-line react/style-prop-object
              style="mapbox://styles/mapbox/streets-v11"
              fitBoundsOptions={{ duration: 0 }}
              containerStyle={{
                height: "40vh",
                width: "800px",
              }}
              
              center={[selectedJobLong, selectedJobLat]}
            >
              <Marker coordinates={[selectedJobLong, selectedJobLat]} ><div ><img src={marker} alt="pin"/></div></Marker>
            </Map>
            {console.log([selectedJobLong, selectedJobLat])}
          </div>
        </Modal>
      </div>
    </Container>
  );
});

export default ViewAssets;
