import React, { useState, useEffect } from "react";
import { useDispatch, useSelector, RootStateOrAny } from "react-redux";
import styled from "styled-components";
import { Formik, Form, Field } from "formik";
import CircularProgress from "@mui/material/CircularProgress";
import CheckboxCustom from "../CheckboxCustom/CheckboxCustom";

//import ClientLocations from "./ClientLocations";

import {
  addNewAsset,
  resetWasAssetAdded,
  updateAssetDetails,
  resetAmendingAsset,
} from "../../Redux/asset_management";

const Container = styled.div`
  width: 100%;
  display: flex;
  text-align: center;
  justify-content: top;
  flex-direction: column;
  align-items: center;
  margin-top: 4%;
`;

const Button = styled.button`
  width: 60%;
  padding: 1rem;
  background-color: transparent;
  border-color: white;
  margin: 2rem;
  font-size: 2rem;
  font-weight: bold;
  color: white;
  border-radius: 25px;
  cursor: pointer;
`;

const FormField = styled.div`
  color: black;
  padding: 0.5rem;
  font-size: 2rem;
  margin-bottom: 2%;
`;

const FIELD_STYLING = {
  width: "70%",
  fontSize: "1.5rem",
  paddingLeft: "12.5%",
  paddingRight: "12.5%",
  paddingTop: "5%",
  paddingBottom: "5%",
};

const FormLabel = styled.label`
  color: white;
  font-weight: bold;
`;

const ButtonBack = styled.button`
  width: 150px;
  padding: 1.2rem;
  margin-bottom: 1%;
  background-color: white;
  border-color: white;
  font-weight: bold;
  color: #2b228a;
  border-radius: 25px;
  cursor: pointer;
`;

const ContainerBackButton = styled.div`
  width: 100%;
  display: flex;
  text-align: center;
  justify-content: top;
  flex-direction: column;
  align-items: left;
`;

declare interface assetDetails {
  clientLocationId: number;
  assetID: string;
  assetName: string;
  clientsAssetID: string;
  description: string;
  clientDescription: string;
  isActive: number;
}

declare interface assetEditDetails {
  assetID: string;
  clientsAssetID: string;
  description: string;
  clientDescription: string;
  isActive: number;
  idassets: number;
}

const AssetForm = (props: any) => {
  const clientLocationId = props.clientLocationId;
  const [submitted, setSubmitted] = useState(false);
  const assetManagement = useSelector(
    (state: RootStateOrAny) => state.assetManagement
  );
  const dispatch = useDispatch();

  //Sets Asset Management Page to a select option
  const switchComponent = props.controller;
  const switchTheView = (value: number) => {
    switchComponent(value);
  };

  //Resetting for the useEffect below
  useEffect(() => {
    dispatch(resetAmendingAsset());
    dispatch(resetWasAssetAdded());
  }, []); //eslint-disable-line

  useEffect(() => {
    if (assetManagement.amendingAsset === "Successfully updated" && submitted) {
      setTimeout(() => {
        switchTheView(0);
      }, 1500);
    }
  }, [assetManagement.amendingAsset]); //eslint-disable-line

  useEffect(() => {
    if (
      assetManagement.wasAssetAdded === "Successfully added new asset!" &&
      submitted
    ) {
      setTimeout(() => {
        switchTheView(2);
      }, 2500);
    }
  }, [assetManagement.wasAssetAdded]); //eslint-disable-line

  // Amending a Asset
  if (props.assetID !== undefined) {
    return (
      <Container>
        <ContainerBackButton>
          <ButtonBack onClick={props.controller}>{"<-- Back"}</ButtonBack>
        </ContainerBackButton>
        <Formik
          initialValues={{
            idassets: props.assetID.value as number,
            assetID: props.assetID.label as string,
            clientsAssetID: props.assetID.asset_id_client as string,
            description: props.assetID.description as string,
            clientDescription: props.assetID.client_description as string,
            isActive: props.assetID.isActive as number,
          }}
          onSubmit={async (values) => {
            const editDetails: assetEditDetails = {
              idassets: values.idassets,
              assetID: values.assetID,
              clientsAssetID: values.clientsAssetID,
              description: values.description,
              clientDescription: values.clientDescription,
              isActive: values.isActive,
            };
            dispatch(updateAssetDetails(editDetails));
            setSubmitted(true);
          }}
        >
          {assetManagement.amendingAsset !== "" &&
          assetManagement.amendingAsset !== "loading" ? (
            <p style={{ color: "white" }}>{assetManagement.amendingAsset}</p>
          ) : (
            <Form>
              {/* Display loading whilst waiting on server response */}
              {assetManagement.amendingAsset === "loading" ? (
                <div>
                  <CircularProgress className={"circ-prog"} size={"3em"} />
                </div>
              ) : (
                <tr>
                  <th>
                    <FormLabel>Asset ID (AVCRO)</FormLabel>
                    <FormField>
                      <Field
                        id="assetID"
                        style={FIELD_STYLING}
                        name="assetID"
                        autoComplete="new-password"
                      />
                    </FormField>

                    <FormLabel>Description</FormLabel>
                    <FormField>
                      <Field
                        id="description"
                        style={FIELD_STYLING}
                        name="description"
                        autoComplete="new-password"
                      />
                    </FormField>
                  </th>
                  <th>
                  <FormLabel>Client Asset ID</FormLabel>
                    <FormField>
                      <Field
                        id="clientsAssetID"
                        style={FIELD_STYLING}
                        name="clientsAssetID"
                        autoComplete="new-password"
                      />
                    </FormField>
                    <FormLabel>Clients Description</FormLabel>
                    <FormField>
                      <Field
                        id="clientDescription"
                        style={FIELD_STYLING}
                        name="clientDescription"
                        autoComplete="new-password"
                      />
                    </FormField>
                    <FormField>
                      <FormLabel>Active Asset</FormLabel>
                      <Field
                        id="isActive"
                        component={CheckboxCustom}
                        name="isActive"
                        autoComplete="new-password"
                      />
                    </FormField>
                  </th>
                </tr>
              )}
              <Button type="submit">Edit</Button>
            </Form>
          )}
        </Formik>
      </Container>
    );
  } else {
    return (
      <Container>
        <Formik
          initialValues={{
            clientLocationId: clientLocationId as number,
            assetID: "" as string,
            assetName: "" as string,
            clientsAssetID: "" as string,
            description: "" as string,
            clientDescription: "" as string,
            isActive: 1 as number,
          }}
          onSubmit={async (values) => {
            const details: assetDetails = {
              clientLocationId: values.clientLocationId,
              assetID: values.assetID,
              assetName: values.assetName,
              clientsAssetID: values.clientsAssetID,
              description: values.description,
              clientDescription: values.clientDescription,
              isActive: values.isActive,
            };
            dispatch(addNewAsset(details));
            setSubmitted(true);
          }}
        >
          {assetManagement.wasAssetAdded !== "" &&
          assetManagement.wasAssetAdded !== "loading" ? (
            <p style={{ color: "white" }}>{assetManagement.wasAssetAdded}</p>
          ) : (
            <Form>
              {/* Display loading whilst waiting on server response */}
              {assetManagement.wasAssetAdded === "loading" ? (
                <div>
                  <CircularProgress className={"circ-prog"} size={"3em"} />
                </div>
              ) : (
                <tr>
                  <th>
                    <FormLabel>Asset ID (AVCRO)</FormLabel>
                    <FormField>
                      <Field
                        id="assetID"
                        style={FIELD_STYLING}
                        name="assetID"
                        autoComplete="new-password"
                      />
                    </FormField>

                    <FormLabel>Description</FormLabel>
                    <FormField>
                      <Field
                        id="description"
                        style={FIELD_STYLING}
                        name="description"
                        autoComplete="new-password"
                      />
                    </FormField>
                  </th>
                  <th>
                  <FormLabel>Client Asset ID</FormLabel>
                    <FormField>
                      <Field
                        id="clientsAssetID"
                        style={FIELD_STYLING}
                        name="clientsAssetID"
                        autoComplete="new-password"
                      />
                    </FormField>
                    <FormLabel>Clients Description</FormLabel>
                    <FormField>
                      <Field
                        id="clientDescription"
                        style={FIELD_STYLING}
                        name="clientDescription"
                        autoComplete="new-password"
                      />
                    </FormField>
                    <FormField>
                      <FormLabel>Active Asset</FormLabel>
                      <Field
                        id="isActive"
                        component={CheckboxCustom}
                        name="isActive"
                        autoComplete="new-password"
                      />
                    </FormField>
                  </th>
                  
                </tr>
                
              )}
              <Button type="submit">Create Asset</Button>
            </Form>
          )}
        </Formik>
        {/* Modifying of the locations a client operates in. A location (if no longer there)
        will be marked inactive to not destroy the relation in the DB */}
        {/* //<ClientLocations /> */}
      </Container>
    );
  }
};

export default AssetForm;
