import Checkbox from "@mui/material/Checkbox";
import { useState, useEffect } from "react";

import "./CheckboxCustom.css";

// Custom checkbox component for the user add/amend form
const CheckboxCustom = (props: any) => {
  const [checked, setChecked] = useState(props.field.value === 1 ? true : false);

  useEffect(() => {
    setChecked(props.field.value === 1 ? true : false);
  }, [props]);

  return <Checkbox checked={checked} onChange={() => props.form.setFieldValue(`isActive`, checked ? 0 : 1)} id="isActive" classes={{ root: "checkbox-custom" }} />;
};

export default CheckboxCustom;
