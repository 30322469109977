import styled from "styled-components";
import React, { useEffect } from "react";

const Container = styled.div`
  width: 100%;
  display: flex;
  text-align: center;
  justify-content: center;
  flex-direction: column;
`;

const HomePage = styled.div`
  & div {
    display: flex;
    justify-content: center;
    align-items: center;
  }
  & button {
    padding: 1rem;
    background-color: transparent;
    margin: 2rem;
    width: 11rem;
    max-width: 11rem;
    height: 11rem;
    font-size: 1.5rem;
    font-weight: bold;
    color: white;
    border-radius: 25px;
    flex: column;
    vertical-align: middle;
    cursor: pointer;
    border-color: white;
  }
  & h1 {
    color: #ffffff;
    text-align: center;
    font-size: 3rem;
    flex: column;
    vertical-align: middle;
  }
`;

const AdminHome = (props: any) => {
  const switchComponent = props.controller;

  const switchTheView = (value: number) => {
    switchComponent(value);
  };

  useEffect(() => {}, []);

  return (
    <Container>
      <HomePage>
        <div>
          <h1>Admin Home</h1>
        </div>
        <div>
          <button onClick={() => switchTheView(3)}>Locations</button>
          <button onClick={() => switchTheView(2)}>Clients</button>
          <button onClick={() => switchTheView(1)}>Users/Staff</button>
        </div>
        <div>
          <button onClick={() => switchTheView(6)}>Current Job List</button>
          <button onClick={() => switchTheView(4)}>Reporting</button>
          <button onClick={() => switchTheView(5)}>Asset Management</button>
        </div>
      </HomePage>
    </Container>
  );
};

export default AdminHome;
