import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import axios from "axios";

declare interface clientInfo {
  clientId: number;
}

declare interface locationManagement {
  clientId: number;
  locationId: number;
}

export const retrieveActiveLocations = createAsyncThunk("/protected/client/active_locations/get", async (data: clientInfo) => {
  const response = await axios.get("/protected/client/active_locations/get", {
    params: { clientId: data.clientId },
  });
  return await response.data;
});

export const retrieveInactiveLocations = createAsyncThunk("/protected/client/inactive_locations/get", async (data: clientInfo) => {
  const response = await axios.get("/protected/client/inactive_locations/get", {
    params: { clientId: data.clientId },
  });
  return await response.data;
});

// This is used for activating & inactivating an operating location for a client. The backend will handle whether the location
// needs to be activated or inactivated by checking the records first
export const updateLocationDetails = createAsyncThunk("/protected/client/location_management", async (data: locationManagement) => {
  const response = await axios.put("/protected/client/location_management", {
    clientId: data.clientId,
    locationId: data.locationId,
  });
  return await response.data;
});

export const operatingLocationsReducer = createSlice({
  name: "operating_locations" as string,
  initialState: {
    activeLocations: [],
    inactiveLocations: [],
    loading: false,
  },
  reducers: {
    resetOperatingLocationsInitState: (state) => {
      state.activeLocations = [];
      state.inactiveLocations = [];
      state.loading = false;
    },

    // This is just done on the initial useEffect so the user won't see another clients'
    // location data for a moment
    clearLocations: (state) => {
      state.activeLocations = [];
      state.inactiveLocations = [];
    },
  },
  //Users extra Reducers
  extraReducers: (builder) => {
    builder.addCase(retrieveActiveLocations.fulfilled, (state, { payload }) => {
      state.activeLocations = payload;
      state.loading = false;
    });
    builder.addCase(retrieveInactiveLocations.fulfilled, (state, { payload }) => {
      state.inactiveLocations = payload;
      state.loading = false;
    });

    builder.addCase(updateLocationDetails.pending, (state) => {
      state.loading = true;
    });

    builder.addCase(updateLocationDetails.fulfilled, (state, { payload }) => {
      state.activeLocations = payload.active;
      state.inactiveLocations = payload.inactive;
      state.loading = false;
    });
  },
});

export const { clearLocations, resetOperatingLocationsInitState } = operatingLocationsReducer.actions;

export default operatingLocationsReducer.reducer;
