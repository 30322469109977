import styled from "styled-components";
import React, { useEffect, useState } from "react";
import { useDispatch, useSelector, RootStateOrAny } from "react-redux";
import CircularProgress from "@mui/material/CircularProgress";

import { retrieveClients, setChosenClients } from "../../Redux/client";

const Container = styled.div`
  width: 100%;
  display: flex;
  text-align: center;
  justify-content: top;
  flex-direction: column;
`;

const Clients = styled.div`
  width: 100%;
  flex-direction: row;
  justify-content: center;

  @media only screen and (min-width: 1024px) {
    flex-direction: row;
    justify-content: center;
  }
`;

const Button = styled.button`
  width: 100%;
  padding: 1.2rem;
  margin-bottom: 5%;
  background-color: transparent;
  border-color: white;
  font-weight: bold;
  color: white;
  border-radius: 25px;
  cursor: pointer;

  @media only screen and (min-width: 1024px) {
    width: 20%;
    padding: 0.5rem;
    margin: 2rem;
    height: 15vh;
    font-size: 2.5rem;
  }
`;

const PageTitle = styled.h1`
  color: #ffffff;
  text-align: center;
  font-size: 3.5rem;
`;

const ButtonBack = styled.button`
  width: 150px;
  padding: 1.2rem;
  margin-bottom: 1%;
  background-color: white;
  border-color: white;
  font-weight: bold;
  color: #2b228a;
  border-radius: 25px;
  cursor: pointer;
`;

const ContainerBackButton = styled.div`
  width: 100%;
  display: flex;
  text-align: center;
  justify-content: top;
  flex-direction: column;
  align-items: left;
`;

const SelectCompany = (props: any) => {
  const switchComponent = props.controller;

  const [loading, setLoading] = useState(true as any);
  const location = useSelector((state: RootStateOrAny) => state.location);
  const user = useSelector((state: RootStateOrAny) => state.user);
  const client = useSelector((state: RootStateOrAny) => state.client);
  const dispatch = useDispatch();

  useEffect(() => {
    dispatch(
      retrieveClients({ userID: user.userId, locID: location.chosenLocation })
    );
  }, []); //eslint-disable-line

  const chooseClient = (clientLocID: number) => {
    dispatch(setChosenClients(clientLocID));
    switchComponent(true);
  };

  const startLoading = () => {
    setTimeout(() => {
      setLoading(false);
    }, 2000);
  };

  return (
    <Container>
      {props.employee ? (
        <ContainerBackButton>
          <ButtonBack onClick={props.goBack}>{"<-- Back"}</ButtonBack>
        </ContainerBackButton>
      ) : null}
      <div>
        <PageTitle>Please select a client:</PageTitle>
      </div>
      {startLoading()}
      {!loading ? (
        <Clients>
          {client.assignedClients.map((k: any, i: number) => {
            return (
              <Button
                key={k.name}
                onClick={() => chooseClient(k.idclient_locations)}
              >
                {k.name}
              </Button>
            );
          })}
        </Clients>
      ) : (
        <div>
          <CircularProgress className={"circ-prog"} size={"3em"} />
        </div>
      )}
    </Container>
  );
};

export default SelectCompany;
