import React, { useState, useEffect } from "react";
import { useDispatch, useSelector, RootStateOrAny } from "react-redux";
import styled from "styled-components";
import { Formik, Form, Field } from "formik";
import CircularProgress from "@mui/material/CircularProgress";

import ClientLocations from "./ClientLocations";

import {
  addNewClient,
  resetWasClientAdded,
  updateClientDetails,
  resetAmendingClient,
} from "../../Redux/admin";

const Container = styled.div`
  width: 60%;
  display: flex;
  text-align: center;
  justify-content: center;
  flex-direction: column;
  align-items: center;
`;

const Button = styled.button`
  width: 70%;
  padding: 1rem;
  background-color: transparent;
  border-color: white;
  margin: 2rem;
  font-size: 2rem;
  font-weight: bold;
  color: white;
  border-radius: 25px;
  cursor: pointer;
`;

const FormField = styled.div`
  color: black;
  padding: 0.5rem;
  font-size: 2rem;
  margin-bottom: 2%;
`;

const FIELD_STYLING = {
  height: "0.5rem",
  width: "80%",
  fontSize: "1.5rem",
  paddingLeft: "12.5%",
  paddingRight: "12.5%",
  paddingTop: "5%",
  paddingBottom: "5%",
};

const FormLabel = styled.label`
  color: white;
  font-weight: bold;
`;

const PageTitle = styled.div`
  color: #ffffff;
  text-align: center;
  font-size: 3rem;
  margin-bottom: 3%;
  font-weight: bold;
`;

declare interface clientDetails {
  clientId: number;
  clientName: string;
}

const ClientsForm = (props: any) => {
  const [submitted, setSubmitted] = useState(false);
  const admin = useSelector((state: RootStateOrAny) => state.admin);
  const dispatch = useDispatch();

  // Resetting for the useEffect below
  useEffect(() => {
    dispatch(resetAmendingClient());
    dispatch(resetWasClientAdded());
  }, []); //eslint-disable-line

  useEffect(() => {
    if (admin.amendingClient === "updated" && submitted) {
      setTimeout(() => {
        props.setViewingForm(false);
      }, 1500);
    }
  }, [admin.amendingClient]); //eslint-disable-line

  useEffect(() => {
    if (
      admin.wasClientAdded === "Successfully added new client!" &&
      submitted
    ) {
      setTimeout(() => {
        props.setViewingForm(false);
      }, 2500);
    }
  }, [admin.wasClientAdded]); //eslint-disable-line

  // Amending a client
  if (admin.clientToAmend !== null) {
    return (
      <Container>
        <PageTitle>Edit Client: {admin.clientToAmend.label}</PageTitle>
        <Formik
          initialValues={{
            clientId: admin.clientToAmend.idclients as number,
            clientName: admin.clientToAmend.name as string,
          }}
          onSubmit={async (values) => {
            const details: clientDetails = {
              clientId: values.clientId,
              clientName: values.clientName,
            };
            dispatch(updateClientDetails(details));
            setSubmitted(true);
          }}
        >
          <Form>
            <table>
              <tr>
                <th>
                  <FormLabel>Client Name</FormLabel>
                  <FormField>
                    <Field
                      id="clientName"
                      style={FIELD_STYLING}
                      name="clientName"
                      autoComplete="new-password"
                    />
                  </FormField>
                </th>
                <th>
                  <Button type="submit">Update Name</Button>
                </th>
                {/* Display loading whilst waiting on server response */}
                {admin.amendingClient === "loading" ? (
                  <div>
                    <CircularProgress className={"circ-prog"} size={"3em"} />
                  </div>
                ) : null}
                {admin.clientToAmend.error !== undefined ? (
                  <div style={{ color: "white", fontSize: "1.5rem" }}>
                    {admin.clientToAmend.error}
                  </div>
                ) : null}
              </tr>
            </table>
          </Form>
        </Formik>
        {/* Modifying of the locations a client operates in. A location (if no longer there)
        will be marked inactive to not destroy the relation in the DB */}
        <ClientLocations />
      </Container>
    );
  } else {
    return (
      <Container>
        <PageTitle>Adding New Client</PageTitle>
        <Formik
          initialValues={{
            clientName: "" as string,
          }}
          onSubmit={async (values) => {
            const details: clientDetails = {
              clientId: -1,
              clientName: values.clientName,
            };
            dispatch(addNewClient(details));
            setSubmitted(true);
          }}
        >
          <Form>
            <FormLabel>Client Name</FormLabel>
            <FormField>
              <Field
                id="clientname"
                style={FIELD_STYLING}
                name="clientName"
                autoComplete="new-password"
              />
            </FormField>
            <FormLabel>You can define locations the client operates at by going to "Amend an existing client"</FormLabel>
            <Button type="submit">Add Client</Button>
            {/* Display loading whilst waiting on server response */}
            {admin.wasClientAdded === "loading" ? (
              <div>
                <CircularProgress className={"circ-prog"} size={"3em"} />
              </div>
            ) : null}
            {/* Show user feedback message based on what the backend responded with */}
            {admin.wasClientAdded !== "" &&
            admin.wasClientAdded !== "loading" ? (
              <div style={{ color: "white" }}>{admin.wasClientAdded}</div>
            ) : null}
          </Form>
        </Formik>
      </Container>
    );
  }
};

export default ClientsForm;
