import QRCode from "qrcode";
import styled from "styled-components";
import { useState, useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { retrieveAssets } from "../../Redux/asset";
import DownloadIcon from "@mui/icons-material/Download";
import CircularProgress from "@mui/material/CircularProgress";

const Button = styled.button`
  width: 45%;
  padding: 1rem;
  background-color: transparent;
  border-color: white;
  margin: 2rem;
  height: 10vh;
  font-size: 2rem;
  font-weight: bold;
  color: white;
  border-radius: 25px;
  cursor: pointer;
`;

const Container = styled.div`
  width: 70%;
  display: flex;
  text-align: center;
  justify-content: top;
  flex-direction: column;
  align-items: center;
`;

const InnerContainer = styled.div`
  width: 100%;
  display: flex;
  text-align: center;
  justify-content: top;
  flex-direction: column;
  align-items: center;
`;

const QRcode = styled.div`
  width: 100%;
  display: flex;
  text-align: center;
  justify-content: top;
  flex-direction: column;
  align-items: center;
`;

const PageTitle = styled.text`
  color: #ffffff;
  text-align: center;
  font-size: 3rem;
`;

const QRCodeGenerator = (props) => {
  const [isGenerated, setIsGenerated] = useState(0);
  const [isLoading, setIsLoading] = useState(true);
  const controller = props.controller;
  const asset = useSelector(function (state) {
    return state.asset;
  }); //eslint-disable-line
  const dispatch = useDispatch();

  useEffect(() => {
    dispatch(retrieveAssets());
  }, []); //eslint-disable-line

  // Load screen before generate QR can be generated. Avoids loading wrong qr.
  setTimeout(() => {
    setIsLoading(false);
  }, 3000);

  //Return the most recent Asset created
  const newAsset = Object.values(asset.assets).reduce(function (a, b) {
    return asset.assets[a] > asset.assets[b] ? a : b;
  });

  //Generate Download file based on a selected asset (using props)
  var download = function () {
    var link = document.createElement("a");
    link.download = `${props.asset.label}.png`;

    link.href = document.getElementById("element-to-print").toDataURL();
    link.click();
  };

  //Generate QR based on a selected asset (using props)
  const generateQR = (any) => {
    setIsLoading(true);
    setTimeout(() => {
      let str = props.asset.unique_qr_id;
      var canvas = document.getElementById("element-to-print");
      var options = {
        errorCorrectionLevel: "M",
        margin: 4,
        small: false,
        scale: 10,
      };
      var ctx = canvas.getContext("2d");

      QRCode.toCanvas(canvas, str, options, function (error) {
        if (error) console.error(error);
      });

      ctx.font = "bold 25px serif";
      ctx.textAlign = "center";
      ctx.fillText(props.asset.asset_id_client, 185, 360);

      setIsLoading(false);
      setIsGenerated(true);
    }, 500);
  };

  //Generate Download file based on the newly created asset
  var downloadNewAsset = function () {
    var link = document.createElement("a");
    link.download = `${newAsset.label}.png`;
    link.href = document.getElementById("element-to-print").toDataURL();
    link.click();
  };

  //Generate QR on the newly created asset
  const generateQRNewAsset = (any) => {
    setIsLoading(true);
    setTimeout(() => {
      let str = newAsset.unique_qr_id;
      var canvas = document.getElementById("element-to-print");
      var options = {
        errorCorrectionLevel: "M",
        margin: 4,
        small: false,
        scale: 10,
      };
      var ctx = canvas.getContext("2d");

      QRCode.toCanvas(canvas, str, options, function (error) {
        if (error) console.error(error);
      });

      ctx.font = "bold 25px serif";
      ctx.textAlign = "center";
      ctx.fillText(newAsset.label, 185, 360);

      setIsLoading(false);
      setIsGenerated(true);
    }, 1500);
  };

  return (
    <Container>
      {props.asset === undefined ? (
        <InnerContainer>
          <p>
            <canvas id="element-to-print" align="center" />
          </p>

          {isLoading ? (
            <div>
              <CircularProgress className={"circ-prog"} size={"3em"} />
            </div>
          ) : null}

          {isGenerated === true ? (
            <QRcode>
              <PageTitle>{newAsset.label}</PageTitle>
              <Button onClick={() => downloadNewAsset()}>
                Download <DownloadIcon style={{ fontSize: "35px" }} />
              </Button>
            </QRcode>
          ) : null}

          {!isLoading && !isGenerated ? (
            <Button onClick={() => generateQRNewAsset()}>Generate QR</Button>
          ) : null}
          <Button onClick={() => controller()}>Done</Button>
        </InnerContainer>
      ) : (
        <InnerContainer>
          <PageTitle>{props.asset.label}</PageTitle>
          <p>
            <canvas
              id="element-to-print"
              align="center"
              aria-label="Canvas button"
            />
          </p>
          {/* <textarea id="text">{props.asset.asset_id_client}</textarea> */}

          {isGenerated === true ? (
            <QRcode>
              <Button onClick={() => download()}>
                Download <DownloadIcon style={{ fontSize: "35px" }} />
              </Button>
            </QRcode>
          ) : null}

          {!isGenerated ? (
            <Button onClick={() => generateQR()}>Generate QR</Button>
          ) : null}

          <Button onClick={() => controller()}>Done</Button>
        </InnerContainer>
      )}
    </Container>
  );
};

export default QRCodeGenerator;
