import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import axios from "axios";

// declare interface selectedCompanyAssets {
//   clientLocationId: number;
// }

declare interface assetDetails {
  clientLocationId: number;
  assetID: string;
  assetName: string,
  clientsAssetID: string,
  description: string,
  clientDescription: string,
  isActive: number,
}

declare interface assetEditDetails {
  assetID: string;
  clientsAssetID: string;
  description: string;
  clientDescription: string;
  isActive: number;
  idassets: number;
}

// export const retrieveAssets = createAsyncThunk(
//   "/protected/assets/get",
//   async() => {
//     const response = await axios.get("/protected/assets/get");
//     return await response.data;
//   }
// );

export const retrieveAssets = createAsyncThunk(
  "/protected/all_assets/get",
  async () => {
    const response = await axios.get("/protected/all_assets/get");
    return await response.data;
  }
);


export const updateAssetDetails = createAsyncThunk(
  "/protected/assets/update",
  async (data: assetEditDetails) => {
    const response = await axios.put("/protected/assets/update", {
      idassets: data.idassets,
      assetID: data.assetID,
      clientsAssetID: data.clientsAssetID,
      description: data.description,
      clientDescription: data.clientDescription,
      isActive: data.isActive,
    });
    return await response.data;
  }
);

export const addNewAsset = createAsyncThunk(
  "/protected/assets/add",
  async (data: assetDetails) => {
    const response = await axios.post("/protected/assets/add", {
      clientLocationId: data.clientLocationId,
      assetID: data.assetID,
      assetName: data.assetName,
      clientsAssetID: data.clientsAssetID,
      description: data.description,
      clientDescription: data.clientDescription,
      isActive: data.isActive,
    });
    return await response.data;
  }
);

export const assetManagementReducer = createSlice({
  name: "assetManagement" as string,
  initialState: {
    clientLocation: -1 as number,
    assets: [],
    assetToAmend: -1,
    amendingAsset: "",
    wasAssetAdded: "",
  },
  reducers: {
    resetAssetManagementInitState: (state) => {
      state.assets = [];
      state.assetToAmend = -1;
      state.amendingAsset = "";
      state.wasAssetAdded = "";
    },

    amendAsset: (state, { payload }) => {
      state.assetToAmend = payload;
    },
    resetAmendingAsset: (state) => {
      state.amendingAsset = "";
    },
    resetWasAssetAdded: (state) => {
      state.wasAssetAdded = "";
    },
  },
  extraReducers: (builder) => {
    builder.addCase(retrieveAssets.fulfilled, (state, { payload }) => {
      state.assets = payload;
    });
    builder.addCase(updateAssetDetails.pending, (state) => {
      state.amendingAsset = "loading";
    });
    builder.addCase(updateAssetDetails.fulfilled, (state, { payload }) => {
      state.assetToAmend = payload;
      state.amendingAsset = payload.error === undefined ? "Successfully updated" : "error";
    });
    builder.addCase(addNewAsset.pending, (state) => {
      state.wasAssetAdded = "loading";
    });
    builder.addCase(addNewAsset.fulfilled, (state, { payload }) => {
      state.wasAssetAdded = payload;
    });
  },
});

export const { resetWasAssetAdded, resetAmendingAsset, resetAssetManagementInitState, amendAsset } = assetManagementReducer.actions;

export default assetManagementReducer.reducer;
