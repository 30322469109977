import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import axios from "axios";

declare interface selectedCompanyAssets {
  clientLocID: number;
  timeAndDateFrom: string;
  timeAndDateTo: string;
}

declare interface selectedAssets {
  idassets: number;
}

export const retrieveClientsAssets = createAsyncThunk("/employee/assets/get", async (data: selectedCompanyAssets) => {
  const response = await axios.get("/employee/assets/get", {
    params: { clientLocID: data.clientLocID, timeAndDateFrom: data.timeAndDateFrom, timeAndDateTo: data.timeAndDateTo },
  });
  return await response.data;
});

export const retrieveClientLocationAsset = createAsyncThunk("/employee/client_location_assets/get", async (data: selectedAssets) => {
  const response = await axios.get("/employee/client_location_assets/get", {
    params: { idassets: data.idassets },
  });
  return await response.data;
});

export const retrieveAssets = createAsyncThunk(
  "/protected/all_assets/get",
  async () => {
    const response = await axios.get("/protected/all_assets/get");
    return await response.data;
  }
);

export const assetReducer = createSlice({
  name: "asset" as string,
  initialState: {
    assignedClientsAssets: [],
    assets: [],
    clientLocationAssets: [],
    // chosenClientsAssets: -1 as number,
  },
  reducers: {
    resetAssetInitState: (state) => {
      state.assignedClientsAssets = [];
      state.assets = [];
      state.clientLocationAssets = [];
    },
    // setChosenClientsAssets: (state, { payload }) => {
    //   state.chosenClientsAssets = payload;
    // },
  },
  extraReducers: (builder) => {
    builder.addCase(retrieveClientsAssets.fulfilled, (state, { payload }) => {
      state.assignedClientsAssets = payload;
    });
    builder.addCase(retrieveAssets.fulfilled, (state, { payload }) => {
      state.assets = payload;
    });
    builder.addCase(retrieveClientLocationAsset.fulfilled, (state, { payload }) => {
      state.clientLocationAssets = payload;
    });
  },
});

export const { resetAssetInitState } = assetReducer.actions;

export default assetReducer.reducer;
