import React, { useState, useEffect } from "react";
import { useDispatch, useSelector, RootStateOrAny } from "react-redux";
import Select from "react-select";
import styled from "styled-components";
import QRCodeGenerator from "./QRCodeGenerator";

import {
  retrieveActiveLocations,
  //clearLocations,
} from "../../Redux/operating_locations";
import AssetForm from "./AssetForm";

import { retrieveClients } from "../../Redux/admin";
import { retrieveAssets, retrieveClientLocationAsset } from "../../Redux/asset";
import { amendAsset } from "../../Redux/asset_management";

//import ClientsForm from "./ClientsForm";

const Container = styled.div`
  width: 100%;
  display: flex;
  text-align: center;
  justify-content: top;
  flex-direction: column;
  align-items: center;
`;

const Button = styled.button`
  width: 40%;
  padding: 1rem;
  background-color: transparent;
  border-color: white;
  margin: 2rem;
  height: 12vh;
  font-size: 2rem;
  font-weight: bold;
  color: white;
  border-radius: 25px;
  cursor: pointer;
`;

const PageTitle = styled.div`
  color: #ffffff;
  text-align: center;
  font-size: 3rem;
`;

const Divider = styled.div`
  margin-top: 4%;
  margin-bottom: 2%;
  color: white;
  font-size: 2rem;
`;

const SelectContainer = styled.div`
  width: 50%;
  margin: 0 auto;
  margin-top: 0.5%;
`;

const SelectContainerInner = styled.div`
  width: 100%;
  margin: 0 auto;
  margin-top: 2%;
`;

const SelectContainerInnerQR = styled.div`
  width: 100%;
  margin: 0 auto;
  margin-top: 2%;
  display: flex;
  text-align: center;
  justify-content: center;
  flex-direction: column;
  align-items: center;
`;

const ButtonBack = styled.button`
  width: 150px;
  padding: 1.2rem;
  margin-bottom: 1%;
  background-color: white;
  border-color: white;
  font-weight: bold;
  color: #2b228a;
  border-radius: 25px;
  cursor: pointer;
`;

const ContainerBackButton = styled.div`
  width: 100%;
  display: flex;
  text-align: center;
  justify-content: top;
  flex-direction: column;
  align-items: left;
`;

const AssetManagement = (props: any) => {
  const [clientSelectedOption, clientSetSelectedOption] = useState(null as any);
  const [locationSelectedOption, locationSetSelectedOption] = useState(
    null as any
  );
  const [assetSelectedOption, assetSetSelectedOption] = useState(null as any);
  const [clientLocation, setClientLocation] = useState(-1 as number);
  //const [individualAsset, setIndividualAsset] = useState(-1 as number);
  const admin = useSelector((state: RootStateOrAny) => state.admin);
  const asset = useSelector((state: RootStateOrAny) => state.asset);
  const operatingLocations = useSelector(
    (state: RootStateOrAny) => state.operatingLocations
  );
  //const [selectedOption, setSelectedOption] = useState(null as any);
  const [view, setView] = useState(0 as number);
  const dispatch = useDispatch();

  useEffect(() => {
    dispatch(retrieveAssets());
    dispatch(retrieveClients());
  }, []); //eslint-disable-line

  const selectClient = (e: any) => {
    clientSetSelectedOption(e);
    dispatch(retrieveActiveLocations({ clientId: e.idclients }));
  };

  // selectClientLocation
  const selectClientLocation = (e: any) => {
    locationSetSelectedOption(e);
    setClientLocation(e.idclient_locations);
    dispatch(retrieveClientLocationAsset({ idassets: e.idclient_locations }));
  };

  //Select Asset
  const selectAsset = (e: any) => {
    assetSetSelectedOption(e);
    //setIndividualAsset(e.value);
    dispatch(amendAsset(e.value));
  };

  const switchComponent = (switchView: number) => {
    setView(switchView);
  };

  const handleClick = (view: any) => () => {
    //setSelectedOption(null);
    setView(view);
  };

  const returnToBeginning = () => {
    //setSelectedOption(null);
    assetSetSelectedOption(null);
    clientSetSelectedOption(null);
    locationSetSelectedOption(null);
    setView(0);
  };

  switch (view) {
    case 0:
      return (
        <Container>
          <ContainerBackButton>
            <ButtonBack onClick={props.controller}>{"<-- Back"}</ButtonBack>
          </ContainerBackButton>
          <PageTitle>Asset Management</PageTitle>
          <Button onClick={handleClick(1)}>Add New</Button>
          <Button onClick={handleClick(3)}>Print QR Code</Button>
          <Button onClick={handleClick(4)}>Edit Existing</Button>
        </Container>
      );
    case 1:
      return (
        <Container>
          <ContainerBackButton>
            <ButtonBack onClick={() => returnToBeginning()}>
              {"<-- Back"}
            </ButtonBack>
          </ContainerBackButton>
          <SelectContainer>
            <PageTitle>Add New Asset</PageTitle>
            <Divider>Client: </Divider>
            <Select
              value={clientSelectedOption}
              onChange={(e) => selectClient(e)}
              options={admin.clients}
              placeholder={"Select a client..."}
            />
          </SelectContainer>
          {clientSelectedOption !== null ? (
            <SelectContainer>
              <Divider>Location: </Divider>
              <Select
                value={locationSelectedOption}
                onChange={(e) => selectClientLocation(e)}
                options={operatingLocations.activeLocations}
                placeholder={"Select a location..."}
              />{" "}{console.log(locationSelectedOption)}
              {locationSelectedOption !== null ? (
                <div>
                  <AssetForm
                    clientLocationId={clientLocation}
                    controller={switchComponent}
                  />
                </div>
              ) : null}
            </SelectContainer>
          ) : null}
        </Container>
      );
    case 2:
      return <QRCodeGenerator controller={returnToBeginning} />;
    case 3:
      if (assetSelectedOption === null) {
        return (
          <Container>
            <ContainerBackButton>
              <ButtonBack onClick={() => returnToBeginning()}>
                {"<-- Back"}
              </ButtonBack>
            </ContainerBackButton>
            <SelectContainer>
              <PageTitle>Generate a QR code</PageTitle>
              <Divider>Client: </Divider>
              <Select
                value={clientSelectedOption}
                onChange={(e) => selectClient(e)}
                options={admin.clients}
                placeholder={"Select a client..."}
              />
            </SelectContainer>

            {clientSelectedOption !== null ? (
              <SelectContainer>
                <Divider>Location: </Divider>
                <Select
                  value={locationSelectedOption}
                  onChange={(e) => selectClientLocation(e)}
                  options={operatingLocations.activeLocations}
                  placeholder={"Select a location..."}
                />{" "}
                {locationSelectedOption !== null ? (
                  <span>
                    <Divider>Asset: </Divider>
                    <Select
                      value={assetSelectedOption}
                      onChange={(e) => selectAsset(e)}
                      options={asset.clientLocationAssets}
                      placeholder={"Select an asset..."}
                    />{" "}
                  </span>
                ) : null}
              </SelectContainer>
            ) : null}
          </Container>
        );
      } else {
        return (
          <SelectContainerInnerQR>
            <QRCodeGenerator
              asset={assetSelectedOption}
              controller={returnToBeginning}
            />
          </SelectContainerInnerQR>
        );
      }
    case 4:
      if (assetSelectedOption === null) {
        return (
          <Container>
            <ContainerBackButton>
              <ButtonBack onClick={() => returnToBeginning()}>
                {"<-- Back"}
              </ButtonBack>
            </ContainerBackButton>
            <SelectContainer>
              <PageTitle>Edit an existing asset</PageTitle>
              <Divider>Client: </Divider>
              <Select
                value={clientSelectedOption}
                onChange={(e) => selectClient(e)}
                options={admin.clients}
                placeholder={"Select a client..."}
              />
            </SelectContainer>

            {clientSelectedOption !== null ? (
              <SelectContainer>
                <Divider>Location: </Divider>
                <Select
                  value={locationSelectedOption}
                  onChange={(e) => selectClientLocation(e)}
                  options={operatingLocations.activeLocations}
                  placeholder={"Select a location..."}
                />{" "}
                {locationSelectedOption !== null ? (
                  <SelectContainerInner>
                    <Divider>Asset: </Divider>
                    <Select
                      value={assetSelectedOption}
                      onChange={(e) => selectAsset(e)}
                      options={asset.clientLocationAssets}
                      placeholder={"Select an asset..."}
                    />{" "}
                  </SelectContainerInner>
                ) : null}
              </SelectContainer>
            ) : null}
          </Container>
        );
      } else {
        return (
          <AssetForm
            assetID={assetSelectedOption}
            controller={returnToBeginning}
          />
        );
      }

    default:
      return <p>Default switch statement state</p>;
  }
};

export default AssetManagement;
