import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import axios from "axios";

// Users interface
declare interface userDetails {
  userId: number;
  username: string;
  password: string;
  firstName: string;
  lastName: string;
  isActive: boolean;
  userRole: number;
}

// Clients interface
declare interface clientDetails {
  clientId: number;
  clientName: string;
}

// Locations interface
declare interface locationDetails {
  locationId: number;
  locationName: string;
}

export const retrieveUsers = createAsyncThunk(
  "/protected/users/get",
  async () => {
    const response = await axios.get("/protected/users/get");
    return await response.data;
  }
);

export const retrieveClients = createAsyncThunk(
  "/protected/clients/get",
  async () => {
    const response = await axios.get("/protected/clients/get");
    return await response.data;
  }
);

export const retrieveLocations = createAsyncThunk(
  "/protected/locations/get",
  async () => {
    const response = await axios.get("/protected/locations/get");
    return await response.data;
  }
);

export const updateUserDetails = createAsyncThunk(
  "/protected/users/update",
  async (data: userDetails) => {
    const response = await axios.put("/protected/users/update", {
      userId: data.userId,
      username: data.username,
      firstName: data.firstName,
      lastName: data.lastName,
      isActive: data.isActive,
      userRole: data.userRole,
      password: data.password,
    });
    return await response.data;
  }
);

export const updateClientDetails = createAsyncThunk(
  "/protected/clients/update",
  async (data: clientDetails) => {
    const response = await axios.put("/protected/clients/update", {
      clientId: data.clientId,
      clientName: data.clientName,
    });
    return await response.data;
  }
);

export const updateLocationDetails = createAsyncThunk(
  "/protected/locations/update",
  async (data: locationDetails) => {
    const response = await axios.put("/protected/locations/update", {
      locationId: data.locationId,
      locationName: data.locationName,
    });
    return await response.data;
  }
);

export const addNewUser = createAsyncThunk(
  "/protected/users/add",
  async (data: userDetails) => {
    const response = await axios.post("/protected/users/add", {
      username: data.username,
      password: data.password,
      firstName: data.firstName,
      lastName: data.lastName,
      isActive: data.isActive,
      userRole: data.userRole,
    });
    return await response.data;
  }
);

export const addNewClient = createAsyncThunk(
  "/protected/clients/add",
  async (data: clientDetails) => {
    const response = await axios.post("/protected/clients/add", {
      clientName: data.clientName,
    });
    return await response.data;
  }
);

export const addNewLocation = createAsyncThunk(
  "/protected/locations/add",
  async (data: locationDetails) => {
    const response = await axios.post("/protected/locations/add", {
      locationName: data.locationName,
    });
    return await response.data;
  }
);


export const adminReducer = createSlice({
  name: "admin" as string,
  initialState: {
    //Users initial State
    users: [],
    userToAmend: -1,
    amendingUser: "",
    wasUserAdded: "",

    //Clients initial State
    clients: [],
    clientToAmend: -1,
    amendingClient: "",
    wasClientAdded: "",

    // Locations initial State
    locations: [],
    locationToAmend: -1,
    amendingLocation: "",
    wasLocationAdded: "",
  },
  reducers: {
    resetAdminInitState: (state) => {
      //Users initial State
      state.users = [];
      state.userToAmend = -1;
      state.amendingUser = "";
      state.wasUserAdded = "";

      //Clients initial State
      state.clients = [];
      state.clientToAmend = -1;
      state.amendingClient = "";
      state.wasClientAdded = "";

      // Locations initial State
      state.locations = [];
      state.locationToAmend = -1;
      state.amendingLocation = "";
      state.wasLocationAdded = "";
    },

    //Users reducers
    amendUser: (state, { payload }) => {
      state.userToAmend = payload;
    },
    resetAmendingUser: (state) => {
      state.amendingUser = "";
    },
    resetWasUserAdded: (state) => {
      state.wasUserAdded = "";
    },

    //Clients reducers
    amendClient: (state, { payload }) => {
      state.clientToAmend = payload;
    },
    resetAmendingClient: (state) => {
      state.amendingClient = "";
    },
    resetWasClientAdded: (state) => {
      state.wasClientAdded = "";
    },

    // Locations reducers
    amendLocation: (state, { payload }) => {
      state.locationToAmend = payload;
    },
    resetAmendingLocation: (state) => {
      state.amendingLocation = "";
    },
    resetWasLocationAdded: (state) => {
      state.wasLocationAdded = "";
    },
  },
  //Users extra Reducers
  extraReducers: (builder) => {
    builder.addCase(retrieveUsers.fulfilled, (state, { payload }) => {
      state.users = payload;
    });
    builder.addCase(updateUserDetails.pending, (state) => {
      state.amendingUser = "loading";
    });
    builder.addCase(updateUserDetails.fulfilled, (state, { payload }) => {
      state.userToAmend = payload;
      state.amendingUser = payload.error === undefined ? "updated" : "error";
    });
    builder.addCase(addNewUser.pending, (state) => {
      state.wasUserAdded = "loading";
    });
    builder.addCase(addNewUser.fulfilled, (state, { payload }) => {
      state.wasUserAdded = payload;
    });

    //Clients extra Reducers
    builder.addCase(retrieveClients.fulfilled, (state, { payload }) => {
      state.clients = payload;
    });
    builder.addCase(updateClientDetails.pending, (state) => {
      state.amendingClient = "loading";
    });
    builder.addCase(updateClientDetails.fulfilled, (state, { payload }) => {
      state.clientToAmend = payload;
      state.amendingClient = payload.error === undefined ? "updated" : "error";
    });
    builder.addCase(addNewClient.pending, (state) => {
      state.wasClientAdded = "loading";
    });
    builder.addCase(addNewClient.fulfilled, (state, { payload }) => {
      state.wasClientAdded = payload;
    });

    //Locations extra Reducers
    builder.addCase(retrieveLocations.fulfilled, (state, { payload }) => {
      state.locations = payload;
    });
    builder.addCase(updateLocationDetails.pending, (state) => {
      state.amendingLocation = "loading";
    });
    builder.addCase(updateLocationDetails.fulfilled, (state, { payload }) => {
      state.locationToAmend = payload;
      state.amendingLocation = payload.error === undefined ? "updated" : "error";
    });
    builder.addCase(addNewLocation.pending, (state) => {
      state.wasLocationAdded = "loading";
    });
    builder.addCase(addNewLocation.fulfilled, (state, { payload }) => {
      state.wasLocationAdded = payload;
    });
  },
});

export const { amendUser, resetAmendingUser, resetWasUserAdded, amendClient, resetWasClientAdded, resetAmendingClient, amendLocation, resetWasLocationAdded, resetAmendingLocation, resetAdminInitState } = adminReducer.actions;

export default adminReducer.reducer;
