import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import axios from "axios";

declare interface reportDate {
  startDate: string;
  endDate: string;
  asset_id: string;
  assetName: String;
  clientLocation: any;
}

export const retrieveReportJobHistory = createAsyncThunk("/protected/reporting/get", async (data: reportDate) => {
  const response = await axios.get("/protected/reporting/get", {
    params: { startDate: data.startDate, endDate: data.endDate, asset_id: data.asset_id, assetName: data.assetName, clientLocation: data.clientLocation },
  });
  return await response.data;
});

export const retrieveReportJobSummary = createAsyncThunk("/protected/reporting_summary/get", async (data: reportDate) => {
  const response = await axios.get("/protected/reporting_summary/get", {
    params: { startDate: data.startDate, endDate: data.endDate, assetName: data.assetName, clientLocation: data.clientLocation },
  });
  return await response.data;
});

export const reportReducer = createSlice({
  name: "report" as string,
  initialState: {
    jobHistory: [],
    jobSummary: [],
    loading: "",
  },
  reducers: {
    resetReportInitState: (state) => {
      state.jobHistory = [];
      state.jobSummary = [];
      state.loading = "";
    },
  },
  extraReducers: (builder) => {
    builder.addCase(retrieveReportJobHistory.pending, (state) => {
      state.loading = "loading";
    });
    builder.addCase(retrieveReportJobHistory.fulfilled, (state, { payload }) => {
      state.jobHistory = payload;
      state.loading = "";
    });
    builder.addCase(retrieveReportJobSummary.pending, (state) => {
      state.loading = "loading";
    });
    builder.addCase(retrieveReportJobSummary.fulfilled, (state, { payload }) => {
      state.jobSummary = payload;
      state.loading = "";
    });
  },
});

export const { resetReportInitState } = reportReducer.actions;

export default reportReducer.reducer;
