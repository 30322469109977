import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import axios from "axios";

//Jobs interface
declare interface jobDetails {
  hours: number;
  litres: number;
  notes: string;
  capLeakCheck: number;
  assetLocationName: string;
  userId: number;
  timeAndDate: string;
  assetId: number;
  latitude: number;
  longitude: number;
}

declare interface AssetUID {
  assetUID: string;
}

//################ ADD NEW JOB
export const addNewJob = createAsyncThunk("/protected/jobs/add", async (data: jobDetails) => {
  const response = await axios.post("/protected/jobs/add", {
    // locationName: data.locationName,
    hours: data.hours,
    litres: data.litres,
    notes: data.notes,
    capLeakCheck: data.capLeakCheck,
    assetLocationName: data.assetLocationName,
    userId: data.userId,
    timeAndDate: data.timeAndDate,
    assetId: data.assetId,
    latitude: data.latitude,
    longitude: data.longitude,
  });
  console.log(response.data)
  return await response.data;
});

export const retrieveSpecificAsset = createAsyncThunk("/employee/assets/specific/get", async (data: AssetUID) => {
  const response = await axios.get("/employee/assets/specific/get", {
    params: { assetUID: data.assetUID },
  });
  return await response.data;
});

export const jobFormReducer = createSlice({
  name: "jobForm" as string,
  initialState: {
    chosenAsset: {} as any,
    wasJobAdded: "",
    error: "" as string,
  },
  reducers: {
    resetJobFormInitState: (state: { chosenAsset: never[]; wasJobAdded: string; error: string; }) => {
      state.chosenAsset = [];
      state.wasJobAdded = "";
      state.error = "";
    },

    setChosenAsset: (state: { chosenAsset: any; }, { payload }: any) => {
      state.chosenAsset = payload;
    },
    // Jobs reducers
    resetWasJobAdded: (state: { wasJobAdded: string; }) => {
      state.wasJobAdded = "";
    },
    resetErrorMsg: (state: { error: string; }) => {
      state.error = "";
    },
    resetChosenAsset: (state: { chosenAsset: {}; }) => {
      state.chosenAsset = {};
    },
  },
  extraReducers: (builder) => {
    //Jobs extra reducers
    builder.addCase(addNewJob.pending, (state) => {
      state.wasJobAdded = "loading";
    });
    builder.addCase(addNewJob.fulfilled, (state, { payload }) => {
      state.wasJobAdded = payload;
    });

    builder.addCase(retrieveSpecificAsset.pending, (state) => {
      state.chosenAsset = {};
    });

    builder.addCase(retrieveSpecificAsset.fulfilled, (state, { payload }) => {
      if (payload.error !== undefined) {
        state.error = payload.error;
      } else {
        state.chosenAsset = payload;
      }
    });
  },
});

export const { setChosenAsset, resetErrorMsg, resetChosenAsset, resetJobFormInitState, resetWasJobAdded } = jobFormReducer.actions;

export default jobFormReducer.reducer;
