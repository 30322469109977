import React, { useState, useEffect } from "react";
import { useDispatch, useSelector, RootStateOrAny } from "react-redux";
import { Redirect } from "react-router-dom";
import SelectLocation from "../SelectLocation/SelectLocation";
import SelectCompany from "../SelectCompany/SelectCompany";
import ViewAssets from "../ViewAssets/ViewAssets";
import JobForm from "../JobForm/JobForm";
import Navbar from "../Navbar/NavBar";
import styled from "styled-components";
import QRCodeScanner from "../QRCodeScanner/QRCodeScanner";

import { verifyAuthentication } from "../../Redux/user";
import { resetLocationInitState } from "../../Redux/location";
import { resetClientsInitState } from "../../Redux/client";
import { resetJobFormInitState } from "../../Redux/job_form";
import { resetAssetInitState } from "../../Redux/asset";

const MainContainer = styled.div`
  width: 95%;
  @media only screen and (min-width: 1024px) {
    height: 100vh;
  }
`;

const LocationContainer = styled.div`
  width: 100%;
  display: flex;
  text-align: center;
  justify-content: center;
  min-height: 95vh;
  max-height: 95vh;
`;
const CompanyContainer = styled.div`
  width: 100%;
  display: flex;
  text-align: center;
  justify-content: center;
  min-height: 95vh;
  max-height: 95vh;
`;

const ViewAssetsContainer = styled.div`
  width: 100%;
  display: flex;
  text-align: center;
  justify-content: center;
  min-height: 95vh;
  max-height: 95vh;
`;

const JobFormContainer = styled.div`
  width: 100%;
  display: flex;
  text-align: center;
  justify-content: center;
  min-height: 95vh;
  max-height: 95vh;
`;

const ControllerEmployee: React.FunctionComponent = (props: any) => {
  // 0: SelectLocation
  // 1: SelectCompany
  // 2: ViewAssets
  // 3: JobForm

  const [maxView] = useState(3);
  const [view, setView] = useState(0);
  const [viewingQRScanner, setViewingQRScanner] = useState(false);

  // Handles the render switching of the different components
  // when going back and forth between pages etc.
  // Guard clause to avoid hitting the default case:
  // Cannot go below 0, and cannot exceed the maxView.
  // maxView holds the value of the component with the biggest
  // number/key assigned to a component.
  const switchComponent = (progression: boolean) => {
    dispatch(verifyAuthentication());
    if (progression && view + 1 <= maxView) {
      setView(view + 1);
    } else if (!progression && view > 0) {
      setView(view - 1);
    }
  };

  const QRCodeSwitch = () => {
    setView(3);
  };

  // Reset redux state of the location, client, & job form states
  // when a user clicks to return back to the start menu
  const returnToBeginning = () => {
    dispatch(resetLocationInitState());
    dispatch(resetClientsInitState());
    dispatch(resetJobFormInitState());
    dispatch(resetAssetInitState());
    setView(0);
  };

  const user = useSelector((state: RootStateOrAny) => state.user);
  const dispatch = useDispatch();

  useEffect(() => {
    dispatch(verifyAuthentication());
  }, []); //eslint-disable-line

  if (!user.sessionExists) {
    return <Redirect to="/login" />;
  } else {
    switch (view) {
      case 0:
        if (!viewingQRScanner) {
          return (
            <MainContainer>
              <Navbar controller={returnToBeginning} />
              <LocationContainer>
                <SelectLocation controller={switchComponent} setViewingQRScanner={setViewingQRScanner} />
              </LocationContainer>
            </MainContainer>
          );
        } else {
          return (
            <div style={{ width: "100%", height: "100vh" }}>
              <QRCodeScanner scanner={setViewingQRScanner} QRCodeSwitch={QRCodeSwitch} />
            </div>
          );
        }
      case 1:
        return (
          <MainContainer>
            <Navbar controller={returnToBeginning} />
            <CompanyContainer>
              <SelectCompany controller={switchComponent} goBack={returnToBeginning} employee={true}/>
            </CompanyContainer>
          </MainContainer>
        );
      case 2:
        return (
          <MainContainer>
            <Navbar controller={returnToBeginning} />
            <ViewAssetsContainer>
              <ViewAssets controller={switchComponent} goBack={setView} />
            </ViewAssetsContainer>
          </MainContainer>
        );
      case 3:
        return (
          <MainContainer>
            <Navbar controller={returnToBeginning} />
            <JobFormContainer>
              <JobForm controller={setView} />
            </JobFormContainer>
          </MainContainer>
        );
      default:
        return <div>default switch statement case</div>;
    }
  }
};

export default ControllerEmployee;
