import React, { useState, useEffect } from "react";
import { useDispatch, useSelector, RootStateOrAny } from "react-redux";
import Select from "react-select";
import styled from "styled-components";

import ClientsForm from "./ClientsForm";

import { retrieveClients, amendClient } from "../../Redux/admin";

const Container = styled.div`
  width: 100%;
  display: flex;
  text-align: center;
  justify-content: top;
  flex-direction: column;
  align-items: center;
`;

const Button = styled.button`
  width: 40%;
  padding: 1rem;
  background-color: transparent;
  border-color: white;
  margin: 2rem;
  height: 10vh;
  font-size: 2rem;
  font-weight: bold;
  color: white;
  border-radius: 25px;
  cursor: pointer;
`;

const PageTitle = styled.div`
  color: #ffffff;
  text-align: center;
  font-size: 3rem;
`;

const Divider = styled.div`
  margin-top: 2%;
  margin-bottom: 2%;
  color: white;
  font-size: 2rem;
`;

const SelectContainer = styled.div`
  width: 40%;
  margin: 0 auto;
  margin-top: 2%;
`;

const ButtonBack = styled.button`
  width: 150px;
  padding: 1.2rem;
  margin-bottom: 1%;
  background-color: white;
  border-color: white;
  font-weight: bold;
  color: #2b228a;
  border-radius: 25px;
  cursor: pointer;
`;

const ContainerBackButton = styled.div`
  width: 100%;
  display: flex;
  text-align: center;
  justify-content: top;
  flex-direction: column;
  align-items: left;
`;

const AdminClients = (props: any) => {
  const [selectedOption, setSelectedOption] = useState(null as any);
  const [viewingForm, setViewingForm] = useState(false);
  const admin = useSelector((state: RootStateOrAny) => state.admin);
  const dispatch = useDispatch();

  const selectClientToAmend = () => {
    dispatch(amendClient(selectedOption));
    setViewingForm(true);
  };

  const backButton = () => {
    setViewingForm(false);
    setSelectedOption(null);
  };

  const handleViewing = () => {
    dispatch(retrieveClients());
    setViewingForm(false);
    setSelectedOption(null);
  };

  useEffect(() => {
    dispatch(retrieveClients());
  }, []); //eslint-disable-line

  if (viewingForm) {
    return (
      <Container>
        <ContainerBackButton>
          <ButtonBack onClick={() => backButton()}>{"<-- Back"}</ButtonBack>
        </ContainerBackButton>
        <ClientsForm setViewingForm={handleViewing} />
      </Container>
    );
  } else {
    return (
      <Container>
        <ContainerBackButton>
          <ButtonBack onClick={props.controller}>{"<-- Back"}</ButtonBack>
        </ContainerBackButton>
        <PageTitle> Clients Add or Edit</PageTitle>
        <Button onClick={() => selectClientToAmend()}>Add New Client</Button>
        <Divider>OR</Divider>
        <PageTitle>Edit an Existing Client:</PageTitle>
        <SelectContainer>
          <Select
            value={selectedOption}
            onChange={(e) => setSelectedOption(e)}
            options={admin.clients}
            placeholder={"Select a client..."}
          />
        </SelectContainer>
        {selectedOption !== null ? (
          <Button onClick={() => selectClientToAmend()}>Edit</Button>
        ) : null}
      </Container>
    );
  }
};

export default AdminClients;
