import React, { useEffect } from "react";
import { useSelector, RootStateOrAny, useDispatch } from "react-redux";
import { Redirect } from "react-router-dom";

import { verifyAuthentication } from "../../Redux/user";

const ControllerMain: React.FunctionComponent = (props: any) => {
  const user = useSelector((state: RootStateOrAny) => state.user);

  const dispatch = useDispatch();

  useEffect(() => {
    dispatch(verifyAuthentication());
  }, []); //eslint-disable-line

  if (!user.sessionExists) {
    return <Redirect to="/login" />;
  } else if (user.roleId === 1) {
    return <Redirect to="/admin" />;
  } else {
    return <Redirect to="/employee" />;
  }
};

export default ControllerMain;
