import React, { useState, useEffect } from "react";
import { Formik, Form, Field } from "formik";
import CircularProgress from "@mui/material/CircularProgress";
import styled from "styled-components";
import { useDispatch, useSelector, RootStateOrAny } from "react-redux";
import Box from "@mui/material/Box";
import Stepper from "@mui/material/Stepper";
import Step from "@mui/material/Step";
import StepLabel from "@mui/material/StepLabel";
import Button from "@mui/material/Button";
import Typography from "@mui/material/Typography";
import Checkbox from "@mui/material/Checkbox";

import { addNewJob, resetWasJobAdded } from "../../Redux/job_form";
import "mapbox-gl/dist/mapbox-gl.css";

import "./Stepper.css";

const Container = styled.div`
  width: 100%;
  display: flex;
  text-align: center;
  justify-content: flex-start;
  flex-direction: column;
  text-align: center;
  color: white;

  @media only screen and (min-width: 1024px) {
    width: 60%;
  }
`;

const PageTitle = styled.div`
  margin-top: 2%;
  color: #ffffff;
  text-align: center;
  font-size: 2.2rem;
  margin-bottom: 5%;

  @media only screen and (min-width: 1024px) {
    font-size: 5rem;
    margin-bottom: 2%;
  }
`;

const SubTitle = styled.div`
  font-size: 1.5rem;
  font-weight: bold;
  color: white;
  margin-bottom: 5%;

  @media only screen and (min-width: 1024px) {
    font-size: 2rem;
  }
`;

const FormField = styled.label`
  width: 100%;
  margin-top: 1vh;
  margin-bottom: 1vh;
`;

const FormLabel = styled.label`
  color: white;
  font-weight: bold;
  font-size: 2rem;
`;

const FIELD_STYLING = {
  width: window.screen.width > 768 ? "15vw" : "60%",
  fontSize: "1.5rem",
};

const ButtonSubmit = styled.button`
  width: 100%;
  margin-top: 10%;
  padding: 1.25% 2.5%;
  background-color: transparent;
  font-size: 1.2rem;
  font-weight: bold;
  border-color: white;
  border-radius: 12px;
  cursor: pointer;
  color: white;

  @media only screen and (min-width: 1024px) {
    width: 25vw;
    font-size: 2rem;
  }
`;

const ConfirmedDetails = styled.div`
  margin-top: 5%;
`;

const JobForm = (props: any) => {
  const jobForm = useSelector((state: RootStateOrAny) => state.jobForm);
  const setView = props.controller;

  return (
    <Container>
      <PageTitle>Job Form</PageTitle>
      <SubTitle>
        Asset: {jobForm.chosenAsset.asset_id} | Description:{" "}
        {jobForm.chosenAsset.description}
      </SubTitle>
      <HorizontalLinearStepper setView={setView} />
    </Container>
  );
};

const CheckboxCap = (props: any) => {
  const [checked, setChecked] = useState(
    props.field.value === 1 ? true : false
  );

  useEffect(() => {
    setChecked(props.field.value === 1 ? true : false);
  }, [props]);

  const canSetCheckbox = () => {
    if (!props.form.values.unserviceable) {
      props.form.setFieldValue(`capCheck`, checked ? 0 : 1);
    }
  };

  return (
    <Checkbox
      checked={checked}
      onChange={() => canSetCheckbox()}
      id="capCheck"
      classes={{ root: "checkbox-custom" }}
    />
  );
};

const CheckboxLeak = (props: any) => {
  const [checked, setChecked] = useState(
    props.field.value === 1 ? true : false
  );

  useEffect(() => {
    setChecked(props.field.value === 1 ? true : false);
  }, [props]);

  const canSetCheckbox = () => {
    if (!props.form.values.unserviceable) {
      props.form.setFieldValue(`leakCheck`, checked ? 0 : 1);
    }
  };

  return (
    <Checkbox
      checked={checked}
      onChange={() => canSetCheckbox()}
      id="leakCheck"
      classes={{ root: "checkbox-custom" }}
    />
  );
};

const CheckboxUnserviceable = (props: any) => {
  const [checked, setChecked] = useState(
    props.field.value === 1 ? true : false
  );

  useEffect(() => {
    setChecked(props.field.value === 1 ? true : false);
  }, [props]);

  return (
    <Checkbox
      checked={checked}
      onChange={() =>
        props.form.setFieldValue(`unserviceable`, checked ? 0 : 1)
      }
      id="unserviceable"
      classes={{ root: "checkbox-custom" }}
    />
  );
};

// Steps for the stepper to display as labels
const steps = ["Hours", "Litres", "Notes", "Cap & Leak Check"];

const HorizontalLinearStepper = (props: any) => {
  const [submitted, setSubmitted] = useState(false);
  const [activeStep, setActiveStep] = useState(0);
  const [capLeakCheckErrorMsg, setcapLeakCheckErrorMsg] = useState(false);
  const [disableFormValues, setDisableFormValues] = useState(false);

  const dispatch = useDispatch();

  const location = useSelector((state: RootStateOrAny) => state.location);
  const user = useSelector((state: RootStateOrAny) => state.user);
  const jobForm = useSelector((state: RootStateOrAny) => state.jobForm);

  useEffect(() => {
    dispatch(resetWasJobAdded());
  }, []); //eslint-disable-line

  //Get current local date and time from browser
  const current = new Date();
  const localDateTime = `${current.getFullYear()}-${
    current.getMonth() + 1
  }-${current.getDate()} ${current.getHours()}:${current.getMinutes()}:${current.getSeconds()}`;

  useEffect(() => {
    if (submitted) {
      setTimeout(() => {
        console.log("Change back to assets table");
        props.setView(0);
      }, 4000);
    }
  }); //eslint-disable-line

  // At each step, one of these will be rendered in the body just below the stepper itself
  const formInputArray = [
    <FormField>
      <div>
        <FormLabel>Hours</FormLabel>
      </div>
      <Field
        style={FIELD_STYLING}
        id="hours"
        name="hours"
        type="number"
        autoComplete="new-password"
        disabled={disableFormValues ? true : false}
      />
    </FormField>,
    <FormField>
      <div>
        <FormLabel>Litres</FormLabel>
      </div>
      <Field
        style={FIELD_STYLING}
        id="litres"
        name="litres"
        type="number"
        autoComplete="new-password"
        disabled={disableFormValues ? true : false}
      />
    </FormField>,
    <FormField>
      <div>
        <FormLabel>Notes</FormLabel>
      </div>
      <Field
        style={FIELD_STYLING}
        id="notes"
        name="notes"
        as="textarea"
        autoComplete="new-password"
      />
    </FormField>,
    <div>
      <FormField>
        <div>
          <FormLabel>Cap Check</FormLabel>
        </div>
        <Field
          id="capCheck"
          component={CheckboxCap}
          name="capCheck"
          autoComplete="new-password"
          disabled="unserviceable"
        />
      </FormField>
      <FormField>
        <div>
          <FormLabel>Leak Check</FormLabel>
        </div>
        <Field
          id="leakCheck"
          component={CheckboxLeak}
          name="leakCheck"
          autoComplete="new-password"
          disabled="unserviceable"
        />
      </FormField>
    </div>,
  ];

  const handleNext = (values: any) => {
    // Check if the user has not checked the cap and leak check when the asset is serviceable
    if (
      activeStep === 3 &&
      !values.unserviceable &&
      (!values.leakCheck || !values.capCheck)
    ) {
      setcapLeakCheckErrorMsg(true);
      setTimeout(() => {
        setcapLeakCheckErrorMsg(false);
      }, 4500);
    } else {
      setActiveStep((prevActiveStep) => prevActiveStep + 1);
    }
  };

  const handleBack = () => {
    if (activeStep === 0) {
      props.setView(2);
    } else {
      setActiveStep((prevActiveStep) => prevActiveStep - 1);
    }
  };

  // Resets the formik form values, disables the ability to enter any values
  // except for the notes field. The notes field also carries over to the new state
  // when the formik state is reset
  const handleUnserviceable = (formikProps: any) => {
    if (!formikProps.values.unserviceable) {
      setDisableFormValues(true);
      const newState = {
        values: {
          unserviceable: true,
          hours: 0,
          litres: 0,
          notes: formikProps.values.notes,
          capCheck: false,
          leakCheck: false,
          chosenLocationName: location.chosenLocationName,
          userId: user.userId,
          timeAndDate: localDateTime,
          assetId: jobForm.chosenAsset.idassets,
        },
      };
      formikProps.resetForm(newState);
      setActiveStep(2);
    } else {
      setDisableFormValues(false);
      setActiveStep(0);
    }
  };

  // console.log(jobForm);
  const [latitude, setLatitude] = useState(0);
  const [longitude, setLongitude] = useState(0);

  useEffect(() => {
    navigator.geolocation.getCurrentPosition(function (position) {
      setLatitude(position.coords.latitude);
      setLongitude(position.coords.longitude);
    });
  });


  return (
    <Box sx={{ width: "100%" }}>
      <Formik
        initialValues={{
          unserviceable: false as boolean,
          hours: 0 as number,
          litres: 0 as number,
          notes: "" as string,
          capCheck: false as boolean,
          leakCheck: false as boolean,
          chosenLocationName: location.chosenLocationName as string,
          userId: user.userId as number,
          timeAndDate: localDateTime as string,
          assetId: jobForm.chosenAsset.idassets as number,
          latitude: latitude,
          longitude: longitude,
        }}
        onSubmit={async (values) => {
          const wasCapLeakCheckDone =
            values.capCheck && values.leakCheck ? 1 : 0;
          const jobFormValues = {
            hours: values.hours,
            litres: values.litres,
            notes: values.notes,
            capLeakCheck: wasCapLeakCheckDone,
            assetLocationName: values.chosenLocationName,
            userId: values.userId,
            timeAndDate: values.timeAndDate,
            assetId: values.assetId,
            latitude: latitude,
            longitude: longitude,
          };

          console.log(jobFormValues);

          dispatch(addNewJob(jobFormValues));
          //   } else {
          //alert(JSON.stringify(values, null, 3));
          //   }
          //   console.log(`submitting...`);
          setSubmitted(true);
        }}
      >
        {({ values, resetForm }) => (
          <Form>
            {/* Stepper maps over the amount of steps needed to do, however
            formik has a form that is wrapped around this entire component to ensure it can store
            all of the form values required. */}
            <Stepper activeStep={activeStep} alternativeLabel>
              {steps.map((label, index) => {
                const stepProps: { completed?: boolean } = {};
                const labelProps: {
                  optional?: React.ReactNode;
                } = {};
                // Steps on the stepper are rendered from here
                return (
                  <Step
                    onClick={() => setActiveStep(index)}
                    key={label}
                    {...stepProps}
                  >
                    <StepLabel
                      {...labelProps}
                      classes={{ label: "stepper-label" }}
                    >
                      {label}
                    </StepLabel>
                  </Step>
                );
              })}
            </Stepper>
            {activeStep === steps.length ? (
              // Body of the form (below the stepper) if all steps are completed
              <React.Fragment>
                {!jobForm.wasJobAdded ? (
                  <ConfirmedDetails>
                    <div style={{ marginBottom: "2%" }}>
                      You have entered the following:
                    </div>
                    <div>
                      Serviceable: {!values.unserviceable ? `YES` : `NO`}
                    </div>
                    <div>Hours: {values.hours}</div>
                    <div>Litres: {values.litres}</div>
                    <div>Notes: {values.notes}</div>
                    <div>
                      Cap & Leak Check:{" "}
                      {values.capCheck && values.leakCheck ? `YES` : `NO`}
                    </div>
                  </ConfirmedDetails>
                ) : null}

                {jobForm.wasJobAdded === "loading" ? (
                  <div>
                    <CircularProgress className={"circ-prog"} size={"3em"} />
                  </div>
                ) : null}
                <p> </p>
                {jobForm.wasJobAdded !== "" &&
                jobForm.wasJobAdded !== "loading" ? (
                  <div style={{ color: "white" }}>{jobForm.wasJobAdded}</div>
                ) : null}

                {jobForm.wasJobAdded !== "" ? null : (
                  <p>
                    <ButtonSubmit type="submit">Submit</ButtonSubmit>
                  </p>
                )}
              </React.Fragment>
            ) : (
              // Body of the form (below the stepper)
              <React.Fragment>
                <Typography sx={{ mt: 3, mb: 1 }} component={"div"}>
                  {formInputArray[activeStep]}
                </Typography>
                {activeStep === 3 && capLeakCheckErrorMsg ? (
                  <p>Please do the cap & leak check stage before continuing.</p>
                ) : null}
                <Box sx={{ display: "flex", flexDirection: "row", pt: 3 }}>
                  <Button
                    classes={{ root: "stepper-btn" }}
                    onClick={handleBack}
                    sx={{ mr: 1 }}
                  >
                    Back
                  </Button>
                  <Box sx={{ flex: "1 1 auto" }} />
                  <div
                    onClick={() =>
                      handleUnserviceable({
                        values: values,
                        resetForm: resetForm,
                      })
                    }
                  >
                    <FormField>
                      <div>
                        <div>Unserviceable</div>
                      </div>
                      <Field
                        id="unserviceable"
                        component={CheckboxUnserviceable}
                        name="unserviceable"
                        autoComplete="new-password"
                      />
                    </FormField>
                  </div>
                  <Box sx={{ flex: "1 1 auto" }} />
                  <Button
                    classes={{ root: "stepper-btn" }}
                    onClick={() => handleNext(values)}
                  >
                    {activeStep === steps.length - 1 ? "Finish" : "Next"}
                  </Button>
                  {/* <Button
                    classes={{ root: "stepper-btn" }}
                    onClick={handleUnserviceable}
                  > */}
                </Box>
              </React.Fragment>
            )}
          </Form>
        )}
      </Formik>
    </Box>
  );
};

export default JobForm;
