import styled from "styled-components";
import React, { useEffect, useState } from "react";
import { useDispatch, useSelector, RootStateOrAny } from "react-redux";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faQrcode } from "@fortawesome/free-solid-svg-icons";
import CircularProgress from "@mui/material/CircularProgress";

import {
  retrieveAssignedLocations,
  setChosenLocation,
} from "../../Redux/location";

const Container = styled.div`
  width: 100%;
  display: flex;
  text-align: center;
  justify-content: top;
  flex-direction: column;
`;

const QRCodeTitle = styled.div`
  font-size: 2rem;
`;

const QRCodeBtn = styled.button`
  background-color: transparent;
  border-color: white;
  font-size: 1.5rem;
  font-weight: bold;
  color: white;
  border-radius: 25px;
  flex: column;
  cursor: pointer;
  padding: 8px;


  @media only screen and (min-width: 1024px) {
    padding: 10px;
    margin: 2rem;
    max-width: 15%;
  }
`;

const QRCodeDiv = styled.div`
  background-color: black;
  border-radius: 20px;
`;

const Locations = styled.div`
  width: 100%;
  display: flex;
  flex-direction: column;
  text-align: center;
  margin: 0 auto;


  @media only screen and (min-width: 1024px) {
    flex-direction: row;
    justify-content: center;
  }
`;

const Button = styled.button`
  width: 100%;
  padding: 1.2rem;
  margin-bottom: 5%;
  background-color: transparent;
  border-color: white;
  font-weight: bold;
  color: white;
  border-radius: 25px;
  cursor: pointer;

  @media only screen and (min-width: 1024px) {
    width: 20%;
    padding: 0.5rem;
    margin: 2rem;
    height: 15vh;
    font-size: 2.5rem;
  }
`;

const PageTitle = styled.h1`
  color: #ffffff;
  text-align: center;
  font-size: 3.5rem;
`;

const PageTitleEmployee = styled.h1`
  color: #ffffff;
  text-align: center;
  font-size: 2.5rem;
`;

const SelectLocation = (props: any) => {
  const switchComponent = props.controller;
  const setViewingQRScanner = props.setViewingQRScanner;

  const [loading, setLoading] = useState(true as any);
  const location = useSelector((state: RootStateOrAny) => state.location);
  const user = useSelector((state: RootStateOrAny) => state.user);
  const dispatch = useDispatch();

  useEffect(() => {
    dispatch(retrieveAssignedLocations({ id: user.userId }));
  }, []); //eslint-disable-line

  const chooseLocation = (locationID: number, locationName: string) => {
    dispatch(setChosenLocation({ ID: locationID, name: locationName }));
    switchComponent(true);
  };

  const startLoading = () => {
    setTimeout(() => {
      setLoading(false);
    }, 2000);
  };

  return (
    <Container>
      {props.adminLogin ? (
        
        <PageTitle>Select a Location:</PageTitle>
      ) : (
        <div>
          <QRCodeBtn onClick={() => setViewingQRScanner(true)}>
            <QRCodeTitle>Scan QR</QRCodeTitle>
            <QRCodeDiv>
              <FontAwesomeIcon icon={faQrcode} size="6x" />
            </QRCodeDiv>
          </QRCodeBtn>

          <PageTitleEmployee>OR</PageTitleEmployee>
          <PageTitleEmployee>Select Your Location:</PageTitleEmployee>
        </div>
      )}
      {startLoading()}
      {!loading ? (
      <Locations>
        {location.assignedLocations.length === 0 ? (
          <p style={{ color: "white" }}>No assigned locations.</p>
        ) : null}
        {location.assignedLocations.map((k: any, i: number) => {
          return (
            <Button
              key={k.name}
              onClick={() => chooseLocation(k.idlocations, k.name)}
            >
              {k.name}
            </Button>
          );
        })}
      </Locations>
      ) : (<div><CircularProgress className={"circ-prog"} size={"3em"} /></div>)}
    </Container>
  );
};

export default SelectLocation;
