import styled from "styled-components";
import React, { useEffect, useState } from "react";
import { useDispatch, useSelector, RootStateOrAny } from "react-redux";
import Table from "../Table/Table";
import CircularProgress from "@mui/material/CircularProgress";
import { setChosenAsset } from "../../Redux/job_form";

import { retrieveClientsAssets } from "../../Redux/asset";

import "./ViewAssets.css";

const Container = styled.div`
  width: 100%;
  text-align: center;
  justify-content: center;
  color: white !important;
`;

const Button = styled.div`
  padding: 0.5rem;
  background-color: white;
  font-size: 1rem;
  font-weight: bold;
  color: #392fa2;
  border-radius: 25px;
  cursor: pointer;
  text-align: center;
`;

const Text = styled.text`
  display: table-cell;
  vertical-align: bottom;
  font-size: 1rem;
  color: white !important;
`;

const Input = styled.input`
  width: 90%;
  margin: 0 auto;
  font-size: 1rem;
`;

const ViewAssets = (props: any) => {
  const switchComponent = props.controller;

  //Get current local date and time from browser
  const current = new Date();
  const localDate = `${current.getFullYear()}-${
    current.getMonth() + 1
  }-${current.getDate()}`

  const [selectedStartDate, setSelectedStartDate] = useState(
    localDate as any
  );
  const [endDateBeforeStart, setEndDateBeforeStart] = useState(false as any);
  const [selectedEndDate, setSelectedEndDate] = useState(localDate as any);
  const [loading, setLoading] = useState(true as any);

  const fromToDate = { from: selectedStartDate, to: selectedEndDate };

  const asset = useSelector((state: RootStateOrAny) => state.asset);
  const client = useSelector((state: RootStateOrAny) => state.client);
  const dispatch = useDispatch();

  useEffect(() => {
    dispatch(
      retrieveClientsAssets({
        clientLocID: client.chosenClients,
        timeAndDateFrom: selectedStartDate,
        timeAndDateTo: selectedEndDate,
      })
    );
  }, []); //eslint-disable-line

  const selectAssetStartJob = (data: any) => {
    dispatch(setChosenAsset(data));
    switchComponent(true);
  };

  // Handle button clicks
  const goBackClientsAdmin = () => {
    props.backToClients(1);
  };

  const goBackClientsEmployee = () => {
    props.goBack(1);
  };

  // Passes data to ViewJobs table for query on jobs
  const viewEditJobs = (data: any) => {
    props.backToClients(3);
    props.fromToDateString(fromToDate);
    props.assetDetails(data);
  };

  // Handle Dates for date selector
  const handleStartDate = (e: any) => {
    setSelectedStartDate(e.target.value);
  };

  const handleEndDate = (e: any) => {
    if (
      new Date(selectedStartDate).getTime() > new Date(e.target.value).getTime()
    ) {
      setEndDateBeforeStart(true);
    } else {
      setEndDateBeforeStart(false);
      setSelectedEndDate(e.target.value);
    }
  };

  const waitForTableUpdate = () => {
    setTimeout(() => {
      setLoading(false);
    }, 3000);
  };

  //waitForTableUpdate();

  const searchDate = () => {
    dispatch(
      retrieveClientsAssets({
        clientLocID: client.chosenClients,
        timeAndDateFrom: selectedStartDate,
        timeAndDateTo: selectedEndDate,
      })
    );
    setLoading(true);
  };

  return (
    <Container>
      {waitForTableUpdate()}
      {props.adminLogin ? (
        <div className="assets-table">
          {!loading ? (
            <Table
              title={
                <table>
                  <thead>
                    <td>
                      <Button onClick={goBackClientsAdmin}>{"<-- Back to Clients"}</Button>
                    </td>
                    <td>
                      <Text>***Default view is jobs today</Text>
                    </td>
                    <Text>From: {selectedStartDate}</Text>
                    <Text>To: {selectedEndDate}</Text>
                  </thead>
                  <tbody>
                    <td></td>
                    <td>
                      <Text>Show job completed between:</Text>
                    </td>
                    <td>
                      {" "}
                      <Input type="date" onChange={(e) => handleStartDate(e)} />
                    </td>
                    <td>
                      {" "}
                      <Input type="date" onChange={(e) => handleEndDate(e)} />
                      {endDateBeforeStart ? (
                        <Text>Must be after start date</Text>
                      ) : null}
                    </td>
                    <td>
                      <Button onClick={searchDate}>Show Jobs</Button>
                    </td>
                  </tbody>
                </table>
              }
              col={[
                {
                  field: "asset_id",
                  title: "Asset ID",
                },
                {
                  field: "description",
                  title: "Description",
                },
                {
                  field: "job_completed_today",
                  title: "View Job Details",

                  render: (rowData: any) =>
                    rowData.job_completed_today === 1 &&
                    selectedStartDate !== "" &&
                    selectedEndDate !== "" ? (
                      <Button
                        style={{ backgroundColor: "Green", color: "white" }}
                        onClick={() => viewEditJobs(rowData)}
                      >
                        View Completed Jobs
                      </Button>
                    ) : (
                      <p>No job completed for this date range</p>
                    ),
                },
              ]}
              data={asset.assignedClientsAssets}
              styles={{
                width: window.screen.width > 768 ? "90%" : "100%",
                margin: "0 auto",
                fontSize: "0.5em",
                fontFamily: "arial",
                backgroundColor: "#2b228a",
                color: "white !important",
              }}
              headerStyles={{
                backgroundColor: "#2b228a",
                color: "white",
              }}
              rowStyles={{
                color: "white",
              }}
              filterStyles={{
                color: "white !important",
              }}
              searchStyles={{
                color: "white !important",
                backgroundColor: "white !important",
              }}
            />
          ) : (
            <CircularProgress className={"circ-prog"} size={"3em"} />
          )}
        </div>
      ) : (
        <div className="assets-table">
          {!loading ? (
            <Table
              title={<Button onClick={goBackClientsEmployee}>Back to Clients</Button>}
              col={[
                {
                  field: "asset_id",
                  title: "Asset ID",
                },
                {
                  field: "description",
                  title: "Description",
                },
                {
                  field: "job_completed_today",
                  title: "New Job",

                  render: (rowData: any) =>
                    rowData.job_completed_today === 1 ? (
                      <Button
                        style={{ backgroundColor: "Green", color: "white" }}
                        onClick={() => selectAssetStartJob(rowData)}
                      >
                        Completed
                      </Button>
                    ) : (
                      <Button onClick={() => selectAssetStartJob(rowData)}>
                        New Job
                      </Button>
                    ),
                },
              ]}
              data={asset.assignedClientsAssets}
              styles={{
                width: window.screen.width > 768 ? "90%" : "100%",
                margin: "0 auto",
                fontSize: "0.5em",
                fontFamily: "arial",
                backgroundColor: "#2b228a",
                color: "white !important",
              }}
              headerStyles={{
                backgroundColor: "#2b228a",
                color: "white",
              }}
              rowStyles={{
                color: "white",
              }}
              filterStyles={{
                color: "white !important",
              }}
              searchStyles={{
                color: "white !important",
                backgroundColor: "white !important",
              }}
            />
          ) : (
            <CircularProgress className={"circ-prog"} size={"3em"} />
          )}
        </div>
      )}
    </Container>
  );
};

export default ViewAssets;
