import React, { useState, useEffect } from "react";
import { useDispatch, useSelector, RootStateOrAny } from "react-redux";
import styled from "styled-components";
import { Formik, Form, Field } from "formik";
import CircularProgress from "@mui/material/CircularProgress";

import {
  addNewLocation,
  resetWasLocationAdded,
  updateLocationDetails,
  resetAmendingLocation,
} from "../../Redux/admin";

const Container = styled.div`
  width: 60%;
  display: flex;
  text-align: center;
  justify-content: center;
  flex-direction: column;
  align-items: center;
`;

const Button = styled.button`
  width: 60%;
  padding: 1rem;
  background-color: transparent;
  border-color: white;
  margin: 2rem;
  font-size: 2rem;
  font-weight: bold;
  color: white;
  border-radius: 25px;
  cursor: pointer;
`;

const FormField = styled.div`
  color: black;
  padding: 0.5rem;
  font-size: 2rem;
  margin-bottom: 2%;
`;

const FIELD_STYLING = {
  width: "50%",
  fontSize: "1.5rem",
  paddingLeft: "12.5%",
  paddingRight: "12.5%",
  paddingTop: "5%",
  paddingBottom: "5%",
};

const FormLabel = styled.label`
  color: white;
  font-weight: bold;
`;

const PageTitle = styled.div`
  color: #ffffff;
  text-align: center;
  font-size: 3rem;
  margin-bottom: 3%;
  font-weight: bold;
`;

declare interface locationDetails {
  locationId: number;
  locationName: string;
}

const LocationsForm = (props: any) => {
  const [submitted, setSubmitted] = useState(false);
  const admin = useSelector((state: RootStateOrAny) => state.admin);
  const dispatch = useDispatch();

  // Resetting for the useEffect below
  useEffect(() => {
    dispatch(resetAmendingLocation());
    dispatch(resetWasLocationAdded());
  }, []); //eslint-disable-line

  useEffect(() => {
    if (admin.amendingLocation === "updated" && submitted) {
      setTimeout(() => {
        props.setViewingForm(false);
      }, 1500);
    }
  }, [admin.amendingLocation]); //eslint-disable-line

  useEffect(() => {
    if (
      admin.wasLocationAdded === "Successfully added new location!" &&
      submitted
    ) {
      setTimeout(() => {
        props.setViewingForm(false);
      }, 2500);
    }
  }, [admin.wasLocationAdded]); //eslint-disable-line

  // Amending a location
  if (admin.locationToAmend !== null) {
    return (
      <Container>
        <PageTitle>Edit Location: {admin.locationToAmend.label}</PageTitle>
        <Formik
          initialValues={{
            locationId: admin.locationToAmend.idlocations as number,
            locationName: admin.locationToAmend.name as string,
          }}
          onSubmit={async (values) => {
            const details: locationDetails = {
              locationId: values.locationId,
              locationName: values.locationName,
            };
            dispatch(updateLocationDetails(details));
            setSubmitted(true);
          }}
        >
          <Form>
            <FormLabel>Location Name</FormLabel>
            <FormField>
              <Field
                id="locationName"
                style={FIELD_STYLING}
                name="locationName"
                autoComplete="new-password"
              />
            </FormField>
            <Button type="submit">Update Details</Button>
            {/* Display loading whilst waiting on server response */}
            {admin.amendingLocation === "loading" ? (
              <div>
                <CircularProgress className={"circ-prog"} size={"3em"} />
              </div>
            ) : null}
            {admin.locationToAmend.error !== undefined ? (
              <div style={{ color: "white", fontSize: "1.5rem" }}>
                {admin.locationToAmend.error}
              </div>
            ) : null}
          </Form>
        </Formik>
      </Container>
    );
  } else {
    return (
      <Container>
        <PageTitle>Adding New Location</PageTitle>
        <Formik
          initialValues={{
            locationName: "" as string,
          }}
          onSubmit={async (values) => {
            const details: locationDetails = {
              locationId: -1,
              locationName: values.locationName,
            };
            dispatch(addNewLocation(details));
            setSubmitted(true);
          }}
        >
          <Form>
            <FormLabel>Location Name</FormLabel>
            <FormField>
              <Field
                id="locationName"
                style={FIELD_STYLING}
                name="locationName"
                autoComplete="new-password"
              />
            </FormField>
            <Button type="submit">Add Location</Button>
            {/* Display loading whilst waiting on server response */}
            {admin.wasLocationAdded === "loading" ? (
              <div>
                <CircularProgress className={"circ-prog"} size={"3em"} />
              </div>
            ) : null}
            {/* Show user feedback message based on what the backend responded with */}
            {admin.wasLocationAdded !== "" &&
            admin.wasLocationAdded !== "loading" ? (
              <div style={{ color: "white" }}>{admin.wasLocationAdded}</div>
            ) : null}
          </Form>
        </Formik>
      </Container>
    );
  }
};

export default LocationsForm;
