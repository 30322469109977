//import React, { useState, useEffect } from "react";
import { useDispatch, useSelector, RootStateOrAny } from "react-redux";
import styled from "styled-components";

import { userLogout } from "../../Redux/user";
import { resetState } from "../../Redux/reset_store";

const Container = styled.div`
  width: 100%;
  display: flex;
  flex-direction: row;
  color: white;
  padding: 0rem 0rem 1rem 0rem;

  @media only screen and (min-width: 1024px) {
    min-height: 5vh;
    max-height: 5vh;
  }
`;

const BtnContainer = styled.div`
  display: flex;
  width: 100%;
  margin-top: 1%;
  margin-right: 1%;
  justify-content: flex-end;
`;

const Link = styled.a`
  width: 100%;
  cursor: pointer;

  @media only screen and (min-width: 1024px) {
    width: 10vw;
  }
`;

const Button = styled.button`
  width: 100%;
  background-color: transparent;
  border-color: white;
  border-radius: 6px;
  cursor: pointer;
  color: white;
  font-size: 1.5rem;
  margin-left: 1%;
  margin-right: 1%;

  @media only screen and (min-width: 1024px) {
    width: 10vw;
    font-size: 1rem;
    height: 4vh;
    border-radius: 12px;
  }
`;

const LogoutBtn = styled.button`
  width: 100%;
  background-color: transparent;
  border-color: white;
  border-radius: 6px;
  cursor: pointer;
  color: white;
  font-size: 1.5rem;

  @media only screen and (min-width: 1024px) {
    width: 10vw;
    font-size: 1rem;
    height: 4vh;
    border-radius: 12px;
  }
`;

const Navbar = (props: any) => {
    const user = useSelector((state: RootStateOrAny) => state.user);
    const controller = props.controller;
    const dispatch = useDispatch();

    const logoutUser = () => {
      dispatch(userLogout());
      dispatch(resetState());
    };

    return (
      <Container>
        <BtnContainer>
          {user.roleId === 1 ? <Button onClick={() => controller()}>Admin Home</Button> : <Button onClick={() => controller()}>Main Menu</Button>}
          <Link href="/login">
            <LogoutBtn onClick={() => logoutUser()}>Logout</LogoutBtn>
          </Link>
        </BtnContainer>
      </Container>
    );
};

export default Navbar;
