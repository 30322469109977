import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import axios from "axios";

declare interface selectedLocation {
  userID: number;
  locID: number;
}

export const retrieveClients = createAsyncThunk("/employee/clients/get", async (data: selectedLocation) => {
  const response = await axios.get("/employee/clients/get", {
    params: { locID: data.locID, userID: data.userID },
  });
  return await response.data;
});

export const clientReducer = createSlice({
  name: "clients" as string,
  initialState: {
    assignedClients: [],
    chosenClients: -1 as number,
  },
  reducers: {
    resetClientsInitState: (state) => {
      state.assignedClients = [];
      state.chosenClients = -1;
    },

    setChosenClients: (state, { payload }) => {
      state.chosenClients = payload;
    },
  },
  extraReducers: (builder) => {
    builder.addCase(retrieveClients.fulfilled, (state, { payload }) => {
      state.assignedClients = payload;
    });
  },
});

export const { setChosenClients, resetClientsInitState } = clientReducer.actions;

export default clientReducer.reducer;
